import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import toGeoJSON from '@mapbox/togeojson/togeojson.js'

// action redux Liste des traces du carnet de voyage
import { setTracks } from '../../actions/index'
// action redux trace courante
import { setCurrentTrack } from '../../actions/index'
// action redux selection d'une trace sur la carte
import { setSelectedTrackId } from '../../actions/index'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { COLOR_TRACK } from '../../commons'


JSON.minify = require("node-json-minify/json.minify.js")

const TracksManageNav = (props) => {

    const { currentTravelbook, tracks, setTracks, currentTrack, setCurrentTrack, selectedTrackId, setSelectedTrackId } = props

    // tableau des traces 
    const [trackList, setTrackList] = React.useState([])

    // contenu du formulaire synchronisé avec currentTrack
    // currentTrack contient la trace courante (current) + previousID
    const { id, gpx, color, visibility } = currentTrack.current

    const track0 = {
        id: '',
        gpx: '',
        color: COLOR_TRACK,
        visibility: true,
        file: null      // contenu du fichier
    }

    // booleen initialisation du formulaire
    const [formReady, setFormReady] = React.useState(false)

    // message d'erreur
    const [msgErr, setMsgErr] = React.useState('') // message d'erreur

    // changement de couleur du formulaire
    const onChangeColor = (e) => {
        e.preventDefault()
        const newCurrentTrack = { ...currentTrack }
        newCurrentTrack.current.color = e.target.value
        setCurrentTrack(newCurrentTrack)
    }

    // simulation du click sur le fichier saisie du nom
    const onClickFile = () => {
        fileInput.current.click()
    }
    // changement de nom de fichier et mémorisation des données dans la propriété file
    const onChangeFile = async (e) => {
        e.preventDefault()
        const newCurrentTrack = { ...currentTrack }
        newCurrentTrack.current.gpx = e.target.files[0].name   // nom du fichier gpx
        newCurrentTrack.current.file = e.target.files[0]    // contenu du fichier
        setCurrentTrack(newCurrentTrack)
    }

    // gestion de la modification des autres champs du formulaire (input text et checkbox)
    const onChangeField = (e) => {
        e.preventDefault()
        const valfield = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
        const newCurrentTrack = { ...currentTrack }
        newCurrentTrack.current[e.target.name] = valfield
        setCurrentTrack(newCurrentTrack)
    }

    // modification du tableau liste des traces

    // selection de la trace dans le tableau des traces
    const onClickRow = (id) => {
        const index = trackList.findIndex(track => track.id === id)
        // mise à jour du tableau 
        let newTrackList = [...trackList]
        if (currentTrack.previousId) {
            // decoche la case de la trace precedement selectionnée
            const indexp = trackList.findIndex(track => track.id === currentTrack.previousId)
            if (indexp !== index) newTrackList[indexp].complete = !newTrackList[indexp].complete
        }
        // bascule de la case cochée
        newTrackList[index].complete = !newTrackList[index].complete
        setTrackList(newTrackList)

        // alimentation /effacement du formulaire
        // memorisation ou non du nouvel id si selectionné
        if (newTrackList[index].complete) {
            // alimentation la trace courante
            const { complete, ...formItem } = newTrackList[index]
            setCurrentTrack({ current: formItem, previousId: id })
        }
        else {
            // effacement du formulaire et remise à zéro de la trace courante
            setCurrentTrack({ current: track0, previousId: '' })
        }
    }

    // lecture du fichier gpx
    const readFileGpx = (reader, file) => {
        return new Promise((resolve, reject) => {
            reader.onload = () => resolve('Fichier ' + file.name + ' lu correctement')
            reader.onerror = () => reject(new Error('Echec de lecture de ' + file))
            reader.readAsText(file, 'UTF-8')
        })
    }

    // ajout d'une trace
    const onClickAdd = () => {

        if (!currentTrack.current.id) {
            // envoi des données concernant la trace ajoutée
            const { complete, ...trackData } = { ...currentTrack.current, travelbookId: currentTravelbook.id }

            // lecture du fichier gpx et conversion geojson
            var reader = new FileReader()
            readFileGpx(reader, trackData.file)
                .then(function (response) {

                    const gpx = reader.result
                    const gpxdom = (new DOMParser()).parseFromString(gpx, 'text/xml')
                    const geojson = toGeoJSON['gpx'](gpxdom)

                    const name = trackData.gpx.replace(/\.[^/.]+$/, "")  // suppression de l'extension de fichier

                    // ajout de la propiete name et suppression de l'horodatage eventuel
                    if (geojson['features']) {
                        for (let i = 0; i < geojson.features.length; i++) {
                            geojson.features[i]['properties'] = { 'name': name }
                        }
                    }
                    const geojsonMinified = JSON.minify(JSON.stringify(geojson, null, 4))
                    // ajout du contenu geojson à la trace

                    const newTrackData =  { geojson: geojsonMinified, ...trackData }
                    delete(newTrackData.file)

                    // creation d'un blob de type fichier geojson minifié
                    const geojsonBlob = new Blob([ geojsonMinified ], {type : 'application/json'})

                    // (post - add-track) - envoi des données du formulaire + fichier geojson vers le serveur
                    const postData = { action: 'add-track', geojsonBlob: geojsonBlob, travelbookSlug: currentTravelbook.slug, ...trackData, }
                    delete(postData.file)

                    const data = new FormData()
                    for (const property in postData) {
                        data.append(property, postData[property])
                    }

                    axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(function (response) {
                            // lecture de l'id de la trace en retour
                            newTrackData.id = response.data.id
                            // mise à jour de la liste des traces du carnet de voyage courant
                            setTracks(tracks.concat([{ ...newTrackData }]))
                            // mise à jour du tableau de traces
                            setTrackList(trackList.concat([{ ...newTrackData, complete: false }]))
                            // effacement des données du formulaire
                            setCurrentTrack({ current: track0, previousId: '' })
                            setMsgErr('')
                        })
                        .catch(function (error) {
                            console.log(error)
                            setMsgErr('>>> Ajout impossible. Transfert geojson en échec')
                        })

                })
                .catch(function (error) {
                    console.log(error)
                    setMsgErr('>>> Ajout impossible. Erreur de lecture du fichier gpx')
                })

        }
    }

    // modification d'une trace
    const onClickUpdate = (trackId) => {

        if (trackId) {
            // envoi des données concernant la trace modifiée
            const { complete, ...trackData } = { ...currentTrack.current, id: 0, travelbookId: currentTravelbook.id }

            if (trackData.file !== undefined) {
                // lecture du fichier gpx précedement désigné et conversion geojson
                var reader = new FileReader()
                readFileGpx(reader, trackData.file)
                    .then(function (response) {

                        const gpx = reader.result
                        const gpxdom = (new DOMParser()).parseFromString(gpx, 'text/xml')
                        const geojson = toGeoJSON['gpx'](gpxdom)

                        const name = trackData.gpx.replace(/\.[^/.]+$/, "")  // suppression de l'extension de fichier

                        // ajout de la propiete name et suppression de l'horodatage eventuel
                        if (geojson['features']) {
                            for (let i = 0; i < geojson.features.length; i++) {
                                geojson.features[i]['properties'] = { 'name': name }
                            }
                        }
                        const geojsonMinified = JSON.minify(JSON.stringify(geojson, null, 4))

                        // ajout du contenu geojson à la trace    
                        const newTrackData =  { geojson: geojsonMinified, ...trackData }
                        delete(newTrackData.file)
    
                        // creation d'un blob de type fichier geojson minifié
                        const geojsonBlob = new Blob([ geojsonMinified ], {type : 'application/json'})
    
                        // (post - add-track) - envoi des données du formulaire + fichier geojson vers le serveur
                        const postData = { action: 'add-track', geojsonBlob: geojsonBlob, travelbookSlug: currentTravelbook.slug, ...trackData, }
                        delete(postData.file)
    
                        const data = new FormData()
                        for (const property in postData) {
                            data.append(property, postData[property])
                        }
    
                        axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                            .then(function (response) {
                                // lecture de l'id de la trace en retour
                                newTrackData.id = response.data.id
                                // mise à jour de la liste des traces du carnet de voyage courant - ajout
                                let newTracks = tracks.concat([{ ...newTrackData }])
                                // mise à jour du tableau de traces - ajout
                                let newTrackList = trackList.concat([{ ...newTrackData, complete: false }])

                                // envoi au serveur pour suppression de la trace modifiée
                                const data2 = new FormData()
                                data2.append('action', 'delete-track')
                                data2.append('id', trackId)

                                axios.post(TRAVELSTEPS_SERVER_URL, data2)
                                    .then(function (response) {
                                        // mise à jour de la liste des traces du carnet de voyage
                                        newTracks = newTracks.filter(track => track.id !== trackId)
                                        setTracks(newTracks)
                                        // mise à jour du tableau des traces
                                        newTrackList = newTrackList.filter(track => track.id !== trackId)
                                        setTrackList(newTrackList)
                                        // effacement des données du formulaire
                                        setCurrentTrack({ current: track0, previousId: '' })
                                    })
                                    .catch(function (error) {
                                        console.log(error)
                                        setMsgErr('>>> Suppression impossible. Transfert des données en échec')
                                    })
                            })
                            .catch(function (error) {
                                console.log(error)
                                setMsgErr('>>> Ajout impossible. Transfert des données en échec')
                            })
                    })
                    .catch(function (error) {
                        console.log(error)
                        setMsgErr('>>> Modification impossible. Erreur de lecture du fichier gpx')
                    })
            }
            else {
                // fichier gpx identique à l'envoi précédent. les données geojson sont déjà disponibles
                const index = tracks.findIndex(track => track.id === trackId)
                const geojsonMinified = tracks[index].geojson

                // ajout du contenu geojson à la trace    
                const newTrackData = { geojson: geojsonMinified, ...trackData }
                delete(newTrackData.file)

                // creation d'un blob de type fichier geojson minifié
                const geojsonBlob = new Blob([geojsonMinified], { type: 'application/json' })

                // (post - add-track) - envoi des données du formulaire + fichier geojson vers le serveur
                const postData = { action: 'add-track', geojsonBlob: geojsonBlob, travelbookSlug: currentTravelbook.slug, ...trackData, }
                delete(postData.file)

                const data = new FormData()
                for (const property in postData) {
                    data.append(property, postData[property])
                }

                axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(function (response) {
                        // lecture de l'id de la trace en retour
                        newTrackData.id = response.data.id                        // mise à jour de la liste des traces du carnet de voyage courant - ajout
                        let newTracks = tracks.concat([{ ...newTrackData }])
                        // mise à jour du tableau de traces - ajout
                        let newTrackList = trackList.concat([{ ...newTrackData, complete: false }])

                        // envoi au serveur pour suppression de la trace modifiée
                        const data2 = new FormData()
                        data2.append('action', 'delete-track')
                        data2.append('id', trackId)

                        axios.post(TRAVELSTEPS_SERVER_URL, data2)
                            .then(function (response) {
                                // mise à jour de la liste des traces du carnet de voyage
                                newTracks = newTracks.filter(track => track.id !== trackId)
                                setTracks(newTracks)
                                // mise à jour du tableau des traces
                                newTrackList = newTrackList.filter(track => track.id !== trackId)
                                setTrackList(newTrackList)
                                // effacement des données du formulaire
                                setCurrentTrack({ current: track0, previousId: '' })
                            })
                            .catch(function (error) {
                                console.log(error)
                                setMsgErr('>>> Suppression impossible. Transfert des données en échec')
                            })
                    })
                    .catch(function (error) {
                        console.log(error)
                        setMsgErr('>>> Ajout impossible. Transfert des données en échec')
                    })

            }
        }
    }

    // suppression d'une trace
    const onClickDelete = (trackId) => {

        if (trackId) {
            // envoi des données concernant la trace à supprimer
            const data = new FormData()
            data.append('action', 'delete-track')
            data.append('id', trackId)

            axios.post( TRAVELSTEPS_SERVER_URL, data )
                .then(function (response) {
                    // mise à jour de la liste des traces du carnet de voyage
                    const newTracks = tracks.filter(track => track.id !== trackId)
                    setTracks(newTracks)
                    // mise à jour du tableau des traces
                    const newTrackList = trackList.filter(track => track.id !== trackId)
                    setTrackList(newTrackList)
                    // effacement des données du formulaire
                    setCurrentTrack({ current: track0, previousId: '' })
                })
                .catch(function (error) {
                    console.log(error)
                    setMsgErr('>>> Suppression impossible. Transfert des données en échec')
                })
        }
    }

    // effacement des données du formulaire
    const onClickErase = () => {
        // mise à jour du tableau si une case est cochée
        if (currentTrack.previousId) {
            let newTrackList = [...trackList]
            // decoche la case de l'item selectionne precedement
            const indp = trackList.findIndex(item => item.id === currentTrack.previousId)
            newTrackList[indp].complete = false
            setTrackList(newTrackList)
        }
        // effacement du formulaire
        setCurrentTrack({ current: track0, previousId: '' })
        setMsgErr('')
    }

    // initialisation du tableau des traces et initialisation à blanc du formulaire
    React.useEffect(() => {
        const setFormData = async () => {
            try {
                // lecture des traces existantes et ajout d'une case à cocher
                const newTrackList = tracks.map((track) => {
                    return ({ ...track, complete: false })
                })
                setTrackList(newTrackList)
                // mise à blanc du formulaire
                setCurrentTrack({ current: track0, previousId: '' })
                setFormReady(true)

            } catch (error) {
                console.log(error)
            }
        }

        setFormData()

        // eslint-disable-next-line
    }, [])


    // // mise à jour du formulaire avec la trace sélectionnée sur la carte
    React.useEffect(() => {
        // equivaut à selectionner la trace dans le tableau des traces
        if (selectedTrackId) {
            onClickRow(selectedTrackId)
            // raz de la selection de la trace sur la carte
            setSelectedTrackId('')
        }

        // eslint-disable-next-line
    }, [selectedTrackId])   // la selection d'une trace sur la carte met à jour le formulaire


    const fileInput = React.createRef()

    if (!formReady) return ('')
    else return (
        <div className="manage-tracks-container container no-gutters">
            <p><strong>Gestion des traces</strong></p>
            <table className="table table-hover table-sm table-responsive">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Id</th>
                        <th scope="col">Fichier</th>
                        <th scope="col">Couleur</th>
                        <th scope="col">Visibilité</th>
                    </tr>
                </thead>
                <tbody>
                    {trackList.map((track, index) => {
                        let bgColor = track.color
                        let cbId = `inputCB-${track.id}`
                        const cbInput = React.createRef()
                        return (
                            <tr key={track.id}>
                                <th><input type="checkbox" id={cbId} ref={cbInput} name="complete" checked={track.complete} onChange={() => onClickRow(track.id)} /></th>
                                <td>{track.id}</td>
                                <td>{track.gpx}</td>
                                <td className="centered"><div className="color-box" style={{ backgroundColor: bgColor }}></div></td>
                                <td className="centered"><input type="checkbox" checked={track.visibility} name="visibility" disabled /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <form>
                <div className="form-group row">
                    <label htmlFor="inputIdGpx" className="col-2 col-form-label col-form-label-sm">Id: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="inputIdGpx" name="id" readOnly value={id} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputFileGpx" className="col-2 col-form-label col-form-label-sm">Fichier: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="inputFileGpx" name="gpx" readOnly value={gpx} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="inputFileGpx2" name="inputFileGpx2" ref={fileInput} onChange={onChangeFile} accept=".gpx" />
                    <div className="col-3">
                        <button type="button" onClick={onClickFile} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="tColor" className="col-3 col-form-label col-form-label-sm">Couleur: </label>
                    <div className="col-3 tcolor">
                        <input type="color" className="form-control form-control-sm" id="tColor" name="tColor" value={color} onChange={onChangeColor} />
                    </div>
                </div>

                <div className="visibilityTrack-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="inputTrackVisibility" name="visibility" checked={visibility} onChange={onChangeField} />
                    <label className="col-form-label col-form-label-sm" htmlFor="inputTrackVisibility">Trace visible</label>
                </div>

                <div className="msgerr"><span>{msgErr}</span></div>

                <div className="row no-gutters justify-content-between buttons-container">
                    <div>
                        <button type="button" onClick={onClickAdd} className="btn btn-primary btn-sm">Ajouter</button>
                    </div>
                    <div>
                        <button type="button" onClick={onClickErase} className="btn btn-primary btn-sm">Effacer</button>
                    </div>
                    <div>
                        <button type="button" onClick={() => onClickUpdate(id)} className="btn btn-primary btn-sm">Modifier</button>
                    </div>
                    <div>
                        <button type="button" onClick={() => onClickDelete(id)} className="btn btn-primary btn-sm">Supprimer</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators - setTracks
// Mise à jour du menu carnet de voyage courant
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setTracks: setTracks,  // Liste des traces
        setCurrentTrack: setCurrentTrack,  // trace courante
        setSelectedTrackId: setSelectedTrackId  // selection de la trace sur la carte
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        tracks: state.tracks,
        currentTrack: state.currentTrack,
        selectedTrackId: state.selectedTrackId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TracksManageNav)