
// root server
//const ROOT_URL = 'http://travelsteps.local'
//const ROOT_URL = 'https://travelsteps-wp.touteslatitudes.fr'
const ROOT_URL = 'https://tsdev-wp.touteslatitudes.fr'

// repertoire assets
export const IMAGE_BASE_URL = ROOT_URL  + '/assets'

// repertoire json server
export const TRAVELSTEPS_SERVER_URL = ROOT_URL  +'/wp-admin/admin-ajax.php'

// centre de la carte par defaut
export const DEFAULT_MAPCENTER =  '47.902447, 1.903858'

// zoom par default
export const DEFAULT_ZOOM = 12

// qualité de la compression d'image
export const QUALITY_IMAGE = 0.7

// largeur maximum d'une image en pixels
export const MAXWIDTH_IMAGE = 1024

// hauteur maximum d'une image en pixels
export const MAXHEIGHT_IMAGE = 768

// Epaisseur du trait pour la trace
export const WEIGHT_TRACK = 3

// Opacité du trait pour la trace
export const OPACITY_TRACK = 0.7

// Couleur par défaut pour une trace
export const COLOR_TRACK = "#0000ff"