import { SET_OPENSUB } from "../actions/index"

// Mise à jour de l'état du menu la page d'accueil
const openSubReducer = (state = false, action) => {
  if (action.type === SET_OPENSUB) {
    return action.payload
  }
  return state
}

export default openSubReducer
