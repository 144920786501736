import React from 'react'
import { connect } from 'react-redux'

import CommentNavBar from "./NavBar/CommentNavBar"

import { IMAGE_BASE_URL } from '../commons'

const Note = (props) => {

    const { currentTravelbook, stepId, note, observer, viewSlider } = props

    const imageBase = IMAGE_BASE_URL + '/' + currentTravelbook.slug + '/image'

    // enregistrement de l'observer uniquement après la création de la première note
    React.useEffect(() => {
        if (!viewSlider) {
            // enregistrement du div à observer pour la note
            const selector = `#note-${note.id}`
            observer.observe(document.querySelector(selector))
        }
        // eslint-disable-next-line
    }, [])

    // affichage du titre du media
    const displayCaption = (caption) => {
        if (caption) return (<p className="note-caption">{caption}</p>)
        else return('')
    }

    const switchNote = (note) => {

        // determmination du type de note
        let mediaType = (note.mediatype) ? note.mediatype : '' // media present
        let noteType = ''
        if (mediaType) {
            // comporte un media
            if (note.description) {
                noteType = (note.textfirst) ? 'text' + mediaType : mediaType + 'text'
            }
            else {
                noteType = mediaType
            }
        }
        else {
            // sans media
            noteType = (note.description) ? 'text' : ''
        }


        switch (noteType) {
            case 'text':
                // note de type text (plain text ou html)
                return (
                    <div className="note-text" dangerouslySetInnerHTML={{ __html: note.description }} />
                )
            case 'image':
                // note de type image
                return (
                    <div className="noteimage">
                        <img className="media-object img-rounded note-image" loading="lazy" width="1024px" src={`${imageBase}/${note.filename}`} alt="" />
                        { displayCaption(note.caption) }
                    </div>
                )
            case 'textimage':
                // note de type texte + image
                return (
                    <div className="notetextimage">
                        <div className="note-text" dangerouslySetInnerHTML={{ __html: note.description }} />
                        <div className="noteimage">
                            <img className="media-object img-rounded note-image" loading="lazy" width="1024px" src={`${imageBase}/${note.filename}`} alt="" />
                            { displayCaption(note.caption) }
                        </div>
                    </div>
                )
            case 'imagetext':
                // note de type image  + texte
                return (
                    <div className="noteimagetext">
                        <div className="noteimage">
                            <img className="media-object img-rounded note-image" loading="lazy" width="1024px" src={`${imageBase}/${note.filename}`} alt="" />
                            { displayCaption(note.caption) }
                        </div>
                        <div className="note-text" dangerouslySetInnerHTML={{ __html: note.description }} />
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <React.Fragment>
            <div id={`note-${note.id}`} className="note">
                { switchNote(note) }
            </div>
            <CommentNavBar note={note} stepId={stepId}></CommentNavBar>
        </React.Fragment>
    )
}


// Redux: mapping des props depuis le store
const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        steps: state.steps,
        openMain: state.openMain,
        viewSlider: state.viewSlider
    }
}

export default connect(mapStateToProps)(Note)