import React from 'react';
import { connect } from 'react-redux'

// composant liste déroulante des notes
import NotesScrollList from './NotesScrollList'
// composant carousel de notes
import NotesCarousel from './NotesCarousel'

// Affichage des Etapes

const NotesViewer = (props) => {

    // variables d'état gloables
    const { steps, viewSlider } = props

    // booleen chargement de la première étape
    const [firstStepReady, setFirstStepReady] = React.useState(false)

    React.useEffect(() => {

        if (steps.length) {
            setFirstStepReady(true)
        }
        else setFirstStepReady(false)

        // eslint-disable-next-line
    }, [steps])


    // affichage des etapes sous la forme d'un slider ou d'une scrollListe
    if (firstStepReady) {
        if (viewSlider) return (<NotesCarousel steps={steps} />)
        else return (<NotesScrollList steps={steps} />)
    }
    else return ('')
}


// Redux: mapping des props depuis le store
const mapStateToProps = state => {
    return {
        steps: state.steps,
        viewSlider: state.viewSlider
    }
}
  
export default connect(mapStateToProps)(NotesViewer)
