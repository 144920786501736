// Reducers Redux

import { combineReducers } from 'redux'

// Etat de l'application
import mapReadyReducer from './mapReadyReducer'
// Courants
import currentNoteReducer from './currentNoteReducer'
import currentStepReducer from './currentStepReducer'
import currentTrackReducer from './currentTrackReducer'
import currentTravelbookReducer from './currentTravelbookReducer'
// Listes
import travelbooksReducer from './travelbooksReducer'
import stepsReducer from './stepsReducer'
import noteIndexesReducer from './noteIndexesReducer'
import tracksReducer from './tracksReducer'
// Menus
import nameMainReducer from './nameMainReducer'
import openMainReducer from './openMainReducer'
import nameSubReducer from './nameSubReducer'
import openSubReducer from './openSubReducer'
// Sélections sur la carte
import selectedStepIdReducer from './selectedStepIdReducer'
import selectedTrackIdReducer from './selectedTrackIdReducer'
import selectedPointReducer from './selectedPointReducer'
// toggle type de vue
import viewSliderReducer from './viewSliderReducer'

const rootReducer = combineReducers({
  mapReady: mapReadyReducer,
  currentNote: currentNoteReducer,
  currentStep: currentStepReducer,
  currentTrack: currentTrackReducer,
  currentTravelbook : currentTravelbookReducer,
  travelbooks : travelbooksReducer,
  steps : stepsReducer,
  noteIndexes : noteIndexesReducer,
  tracks : tracksReducer,
  nameMain : nameMainReducer,
  openMain : openMainReducer,
  nameSub : nameSubReducer,
  openSub : openSubReducer,
  selectedStepId : selectedStepIdReducer,
  selectedTrackId : selectedTrackIdReducer,
  selectedPoint: selectedPointReducer,
  viewSlider: viewSliderReducer
})

export default rootReducer