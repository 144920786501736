import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { IMAGE_BASE_URL } from '../../commons'

// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'

// action redux liste des étapes
import { setSteps } from '../../actions/index'
// action redux menu note courante
import { setCurrentNote } from '../../actions/index'

const NoteDeleteNav = (props) => {

    const { currentTravelbook, currentNote, setCurrentNote, setOpenMain, setOpenSub, currentStep, steps, setSteps } = props

    // on mémorise l'image saisie via le formulaire
    const imageData0 = { src: '' }
    const [imageData, setImageData] = React.useState(imageData0)

    // message d'erreur
    const [msgErr, setMsgErr] = React.useState('')

    // booleen chargement du carnet de voyage
    const [formReady, setFormReady] = React.useState(false)

    // suppression de l'étape
    const onClickDelete = (e) => {

        e.preventDefault()

        const data = new FormData()
        data.append('action', 'delete-note')
        data.append('id', currentNote.id)
        data.append('stepId', currentStep.id)
        data.append('number', currentNote.number)

        axios.post(TRAVELSTEPS_SERVER_URL, data)
            .then(function (response) {
                // mise à jour de la liste des notes
                const newNotes = [...currentStep.notes]
                const index = newNotes.findIndex(note => note.id === currentNote.id) // index de la note courante
                // suppression de la note de la liste des notes
                newNotes.splice(index, 1)
                if (newNotes.length) {
                    // renuméroation des notes suivantes
                    for (let i = index; i < newNotes.length; i++) {
                        newNotes[i].number = Number(newNotes[i].number) - 1     // increment du numero d'ordre des notes suivantes
                    }
                }
                // mise à jour de la liste des étapes
                const newSteps = [...steps]
                const indexStep = steps.findIndex(step => step.id === currentStep.id) // index de l'étape courante
                newSteps[indexStep].notes = newNotes
                setSteps(newSteps)

                // positionnement de la nouvelle note courante
                if (index) {
                    // il existe des notes avant
                    setCurrentNote(newNotes[index - 1])
                }
                else if (newNotes.length) {
                    // il existe des notes après
                    setCurrentNote(newNotes[index])
                }
                else {
                    // la liste de notes est vide
                    setCurrentNote(null)
                }
                // fermeture des menus principal et secondaire
                setOpenMain(false)
                setOpenSub(false)
            })
            .catch(function (error) {
                console.log(error)
                setMsgErr('>>> Suppression en erreur.')
            })
    }

    // chargement des données du formulaire
    React.useEffect(() => {

        const setFormData = async () => {
            try {
                // chargement de l'image si elle a été renseignée
                if (currentNote.mediatype === 'image') {
                    if (currentNote.filename) {
                        const url = IMAGE_BASE_URL + '/' + currentTravelbook.slug + '/image/' + currentNote.filename
                        // memorisation des données de l'image - image deja sur le serveur
                        setImageData({ src: url })
                    }
                }
                else {
                    setImageData(imageData0)
                }
                setMsgErr('')
                setFormReady(true)
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Modification en erreur.')
            }
        }

        if (currentNote) setFormData()

        // eslint-disable-next-line
    }, [currentNote])

    // champ image
    const getImage = (note) => {

        if (note.mediatype === 'image') {
            return (
                <React.Fragment>
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive img-rounded note-image" src={imageData.src} alt="" />
                    </div>
                    <div className="container-fluid d-flex justify-content-center">
                        <p className="note-caption">{note.caption}</p>
                    </div>
                </React.Fragment>
            )
        }
        return ('')
    }

    // champ filename
    const getFilename = (note) => {

        if (note.filename) {
            return (<div className="col-12">{currentNote.filename}</div>)
        }
        return ('')
    }

    if (!formReady) return ('')
    else return (
        <div className="update-step-container container no-gutters">
            <p><strong>Suppression de la note</strong></p>
            <form>
                <div className="form-group row">
                    <div className="col-3 form-id">
                        <input type="text" className="form-control form-control-sm" name="id" readOnly value={currentNote.id} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-12 note-text" dangerouslySetInnerHTML={{ __html: currentNote.description }} />
                </div>

                <div className="form-group row">
                    {getImage(currentNote)}
                </div>

                <div className="form-group row">
                    {getFilename(currentNote)}
                </div>

                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickDelete} className="btn btn-primary btn-sm">Supprimer</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators - setSteps / setStepNav / setCurrentStep
// Mise à jour de la liste des étapes, de la nouvelle étape et du menu étapes
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSteps: setSteps,
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub,
        setCurrentNote: setCurrentNote
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        currentNote: state.currentNote,
        steps: state.steps
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteDeleteNav)