import React from 'react'
import { connect } from "react-redux"

import Collapse from "react-bootstrap/Collapse"

const SubNav = (props) => {

    // sous-menu
    const { nameNav, nameSub, openSub, children } = props

    if (nameSub === nameNav) {
        return (
            <Collapse in={openSub}>
                <div id={nameNav} className="sub-nav menu-container">
                    {children}
                </div>
            </Collapse>
        )
    }
    else {
        return ('')
    }
}

// Redux: mapping des props depuis le store
const mapStateToProps = (state) => {
    return {
        openSub: state.openSub,
        nameSub: state.nameSub
    }
}

export default connect(mapStateToProps)(SubNav)