import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// protection attaque xss
import dompurify from 'dompurify';

// image loader & resizer
import { readAndCompressImage } from 'browser-image-resizer'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { QUALITY_IMAGE, MAXWIDTH_IMAGE, MAXHEIGHT_IMAGE } from '../../commons.js'
import { IMAGE_BASE_URL } from '../../commons'


// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'

// action redux liste des étapes
import { setSteps } from '../../actions/index'
// action redux note courante
import { setCurrentNote } from '../../actions/index'


// Menu Ajout d'une note à l'étape courante
// Les champs du formulaire sont synchronisés sur la variable globale formState
// L'ajout d'une note modifie la variable gloable steps

const NoteUpdateNav = (props) => {

    const { setOpenMain, setOpenSub, currentTravelbook, currentStep, currentNote, setCurrentNote, steps, setSteps } = props

    const emptyForm = {
        id: '',
        description: '',    // plain-text ou Html
        textfirst: true,    // texte en premier par défaut
        mediatype: '',       // type de media
        caption: '',        // legende du media (image/video/audio/gpx)
        visibility: true
    }

    const emptyImageData = { blob: null, base64: null, src: null }

    // on mémorise les items saisis via le formulaire
    const [formState, setFormState] = React.useState(emptyForm)
    const { id, description, textfirst, caption, visibility } = formState

    // on mémorise les données du fichier image
    const [imageFilename, setImageFilename] = React.useState('')
    const [imageData, setImageData] = React.useState(emptyImageData)

    // on mémorise les données du fichier video
    const [videoFilename, setVideoFilename] = React.useState('')
    const [videoData, setVideoData] = React.useState(null)

    // on mémorise les données du fichier audio
    const [audioFilename, setAudioFilename] = React.useState('')
    const [audioData, setAudioData] = React.useState(null)

    // on mémorise les données du fichier gpx
    const [gpxFilename, setGpxFilename] = React.useState('')
    const [gpxData, setGpxData] = React.useState(null)

    // message d'erreur
    const [msgErr, setMsgErr] = React.useState('')

    // booleen initialisation du formulaire
    const [formReady, setFormReady] = React.useState(false)

    const imageInput = React.createRef()
    const videoInput = React.createRef()
    const audioInput = React.createRef()
    const gpxInput = React.createRef()


    // gestion de la modification des champs du formulaire
    const onChangeField = (e) => {
        const valfield = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
        setFormState({ ...formState, [e.target.name]: valfield })
    }

    // simulation du click sur le champ nom des fichiers
    const onClickImage = () => {
        imageInput.current.click()
    }
    const onClickVideo = () => {
        videoInput.current.click()
    }
    const onClickGpx = () => {
        gpxInput.current.click()
    }
    const onClickAudio = () => {
        audioInput.current.click()
    }

    // conversion Blob to Base64 pour une image
    const convertToBase64 = (blob) => {
        return new Promise(resolve => {
            var reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    }

    // modification de l'image
    const onChangeImage = async (e) => {

        e.preventDefault()

        const config = {
            quality: QUALITY_IMAGE,
            maxWidth: MAXWIDTH_IMAGE,
            maxHeight: MAXHEIGHT_IMAGE,
            autoRotate: true,
            debug: false
        }

        const imgfile = e.target.files[0].name
        // lecture et redimensionnement de l'image
        let blobImage = await readAndCompressImage(
            e.target.files[0],
            config
        )
        // conversion base64
        let base64Image = await convertToBase64(blobImage)
        // memorisation des données de l'image
        setImageData({ blob: blobImage, base64: base64Image })
        // mise à jour du nom du fichier image
        setImageFilename(imgfile)
    }

    // modification du fichier video
    const onChangeVideo = async (e) => {
        e.preventDefault()
        setVideoFilename(e.target.files[0].name)  // nom du fichier video
        setVideoData(e.target.files[0])           // contenu du fichier
    }

    // modification du fichier gpx
    const onChangeGpx = async (e) => {
        e.preventDefault()
        setGpxFilename(e.target.files[0].name)  // nom du fichier gpx
        setGpxData(e.target.files[0])           // contenu du fichier
    }

    // modification du fichier audio
    const onChangeAudio = async (e) => {
        e.preventDefault()
        setAudioFilename(e.target.files[0].name)    // nom du fichier audio
        setAudioData(e.target.files[0])             // contenu du fichier
    }

    // mise à zero du formulaire
    const eraseForm = () => {
        setFormState(emptyForm)
        setImageFilename('')
        setImageData(emptyImageData)
        setVideoFilename('')
        setVideoData(null)
        setAudioFilename('')
        setAudioData(null)
        setGpxFilename('')
        setGpxData(null)
    }

    // effacement des données du formulaire
    const onClickErase = (e) => {
        e.preventDefault()
        eraseForm()
    }

    // modification d'une note
    const onClickUpdate = (e) => {

        e.preventDefault()

        if (currentNote && currentNote.id) {

            // determination du type de note 
            let mediatype = ''
            if (imageFilename) mediatype = 'image'
            else if (videoFilename) mediatype = 'video'
            else if (audioFilename) mediatype = 'audio'
            else if (gpxFilename) mediatype = 'gpx'


            if (mediatype || description) {

                // protection xss
                const safeDescription = dompurify.sanitize(description)

                let postData = ({
                    action: 'update-note', travelbookSlug: currentTravelbook.slug, stepId: currentStep.id,
                    ...formState, id: currentNote.id, description: safeDescription, number: currentNote.number
                })

                postData.filename = ''  // nom de fichier par defaut
                if (mediatype) {
                    // ajout du type de media
                    postData.mediatype = mediatype
                    // ajout du nom de fichier et des donnees
                    switch (mediatype) {
                        case 'image':
                            postData.filename = imageFilename
                            if (imageData.blob) postData.fileData = imageData.blob
                            break
                        case 'video':
                            postData.filename = videoFilename
                            if (videoData.blob) postData.fileData = videoData.blob
                            break
                        case 'audio':
                            postData.filename = audioFilename
                            if (audioData.blob) postData.fileData = audioData.blob
                            break
                        case 'gpx':
                            postData.filename = gpxFilename
                            if (gpxData.blob) postData.fileData = gpxData.blob
                            break
                        default:
                            break
                    }
                }

                const data = new FormData()
                for (const property in postData) {
                    data.append(property, postData[property])
                }

                axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(function (response) {

                        const updatedNote = { ...formState, id: currentNote.id, filename: postData.filename, mediatype: mediatype, stepId: currentStep.id, number: currentNote.number }

                        // mise à jour de la liste des notes
                        const newNotes = [...currentStep.notes]
                        const index = newNotes.findIndex(note => note.id === currentNote.id)   // index de la note courante
                        newNotes[index] = updatedNote  // mise à jour de la note

                        // mise à jour de la liste des étapes avec la nouvelle note
                        const indexStep = steps.findIndex(step => step.id === currentStep.id) // index de l'étape courante
                        const newSteps = [...steps]
                        newSteps[indexStep].notes = newNotes
                        setSteps(newSteps)

                        // effacement des données du formulaire
                        eraseForm()
                        setMsgErr('')
                        // fermeture des menus principal et secondaire
                        setOpenMain(false)
                        setOpenSub(false)
                        // mise à jour de la note courante
                        setCurrentNote(updatedNote)
                    })
                    .catch(function (error) {
                        console.log(error)
                        setMsgErr('>>> Modification en erreur.')
                    })
            }
        }
    }


    // chargement des données du formulaire
    React.useEffect(() => {

        const setFormData = async () => {
            try {
                if (currentNote) {
                    // erase form
                    eraseForm()
                    // lecture de la note courante
                    const newFormState = ({ ...emptyForm })
                    newFormState.id = currentNote.id
                    newFormState.mediatype = currentNote.mediatype
                    newFormState.description = currentNote.description ? currentNote.description : ''
                    newFormState.textfirst = currentNote.textfirst
                    newFormState.visibility = currentNote.visibility
                    newFormState.caption = currentNote.caption ? currentNote.caption : ''

                    setFormState(newFormState)
                    // chargement de l'image à la une si elle a été renseignée
                    switch (currentNote.mediatype) {
                        case 'image':
                            if (currentNote.filename) {
                                setImageFilename(currentNote.filename)
                                const url = IMAGE_BASE_URL + '/' + currentTravelbook.slug + '/image/' + currentNote.filename
                                // memorisation des données de l'image - image deja sur le serveur
                                setImageData({ blob: null, base64: null, src: url })
                            }
                            break
                        case 'video':
                            setVideoFilename(currentNote.filename)
                            setVideoData(null)
                            break
                        case 'audio':
                            setAudioFilename(currentNote.filename)
                            break
                        case 'gpx':
                            setVideoFilename(currentNote.filename)
                            setGpxData(null)
                            break
                        default:
                            break
                    }
                    setMsgErr('')
                    setFormReady(true)
                }
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Modification en erreur.')
            }
        }

        if (currentStep) setFormData()

        // eslint-disable-next-line
    }, [currentNote])



    if (!formReady) return ('')
    else return (
        <div className="upd-note-container container no-gutters">
            <p><strong>Modification de la note </strong></p>

            <form>
                <div className="form-group row">
                    <label htmlFor="updNoteId" className="col-2 col-form-label col-form-label-sm">Id: </label>
                    <div className="col-3 form-id">
                        <input type="text" className="form-control form-control-sm" id="updNoteId" name="id" readOnly value={id} onChange={onChangeField} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-2">
                        <label htmlFor="updNoteDesc" className="col-form-label col-form-label-sm">Texte: </label>
                        <br /><br /><br />
                        <div className="html-container form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="updNoteTextFirst" name="textfirst" checked={textfirst} onChange={onChangeField} />
                            <label className="form-check-label" htmlFor="updNoteTextFirst">En premier</label>
                        </div>
                    </div>
                    <div className="col-10">
                        <textarea id="updNoteDesc" className="form-control form-control-sm" name="description" cols="40" rows="5" value={description} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updNoteImage" className="col-2 col-form-label col-form-label-sm">Image: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="updNoteImage" name="image" readOnly value={imageFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="updNoteImageName" name="imageName" ref={imageInput} onChange={onChangeImage} accept="image/*" />
                    <div className="col-3">
                        <button type="button" onClick={onClickImage} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive" src={imageData.src} alt="" />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updNoteTitle" className="col-2 col-form-label col-form-label-sm">Titre: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="updNoteTitre" name="caption" value={caption} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updNoteVideo" className="col-2 col-form-label col-form-label-sm">Video: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="updNoteVideo" name="video" readOnly value={videoFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="updNoteVideoName" name="videoName" ref={videoInput} onChange={onChangeVideo} accept="video/*" />
                    <div className="col-3">
                        <button type="button" onClick={onClickVideo} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updNoteGpx" className="col-2 col-form-label col-form-label-sm">Gpx: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="updNoteGpx" name="gpx" readOnly value={gpxFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="updNoteGpxName" name="gpxName" ref={gpxInput} onChange={onChangeGpx} accept=".gpx" />
                    <div className="col-3">
                        <button type="button" onClick={onClickGpx} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm">Audio: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="updNoteAudio" name="audio" readOnly value={audioFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="updNoteAudioName" name="audioName" ref={audioInput} onChange={onChangeAudio} accept="audio/*" />
                    <div className="col-3">
                        <button type="button" onClick={onClickAudio} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="visibility-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="updNoteVisibility" name="visibility" checked={visibility} onChange={onChangeField} />
                    <label className="form-check-label" htmlFor="updNoteVisibility">Note visible</label>
                </div>

                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickErase} className="btn btn-primary btn-sm">Effacer</button>
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={onClickUpdate} className="btn btn-primary btn-sm">Modifier</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators - setSteps / setStepNav / setCurrentStep
// Mise à jour de la liste des étapes, de la nouvelle étape et du menu étapes
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub,
        setSteps: setSteps,
        setCurrentNote: setCurrentNote
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        currentNote: state.currentNote,
        steps: state.steps
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteUpdateNav)