import { SET_OPENMAIN } from "../actions/index"

// Mise à jour de l'état du menu principal
const openMainReducer = (state = false, action) => {
  if (action.type === SET_OPENMAIN) {
    return action.payload
  }
  return state
}

export default openMainReducer
