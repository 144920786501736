import { SET_SELECTEDSTEPID } from '../actions/index'

// Sélection d'une étape sur la carte
const selectedStepIdReducer = (state = '', action) => {
    if (action.type === SET_SELECTEDSTEPID) {
        return action.payload
    }
    return state
}

export default selectedStepIdReducer