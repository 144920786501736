import React from 'react'
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom'

// Package css
import './App.scss'

// composants
import AdminTravelbook from './AdminTravelbook/AdminTravelbook'
import AdminHome from './AdminHome/AdminHome'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/:slug" children={<Page />} />
        <Route path="/" children={<AdminHome />} />
      </Switch>
    </Router>
  )
}

function Page() {
  const { slug } = useParams()

  return (
    <AdminTravelbook travelbookSlug={slug}/>
  )
}

export default App