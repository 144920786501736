import React from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

// action redux menu page accueil
import { setOpenMain } from "../../actions/index"
import { setNameMain } from "../../actions/index"
import { setOpenSub } from "../../actions/index"
import { setNameSub } from "../../actions/index"

import Collapse from "react-bootstrap/Collapse"

const MainNav = (props) => {

    const { nameNav, openMain, setOpenMain, nameMain, setNameMain, setOpenSub, setNameSub, children } = props

    const defaultOptions = {
        iconStyle: 'menu-light-icon',
        icon: 'fas fa-bars',            // icone menu
        buttonVisibility: true
    }
    // mise à jour des option
    const options =  { ...defaultOptions, ...props.options } 
    const {iconStyle, icon, buttonVisibility} = options

    // mise à jour du menu principal
    const getMainNav = (e, nameNav) => {
        e.preventDefault()

        if (nameNav === nameMain) {
            // cas du menu principal ferme
            if (openMain) {
                setOpenSub(false)
                setNameSub('')
            }
            setOpenMain(!openMain)    // ouverture/fermeture du menu prinipal courant
        }
        else {
            // ouverture d'un nouveau menu principal
            setOpenSub(false)
            setNameSub('')
            setOpenMain(true)
        }

        setNameMain(nameNav)      // nom du menu principal à charger

        // cas du menu principal ferme
        if (!openMain) {
            setOpenSub(false)
            setNameSub('')
        }
    }

    const displayButton = (nameNav, iconStyle, icon, buttonVisibility) => {

        if (buttonVisibility) {
            return(
                <button type="button" className="menu-button"><span className={iconStyle} onClick={(e) => getMainNav(e, nameNav)}><i className={icon}></i></span></button>
            )
        }
        else return('')
    }

    // affichage du bouton et du menu principal
    if (nameNav === nameMain) {
        // affichage du menu
        return (
            <React.Fragment>
                { displayButton(nameNav, iconStyle, icon, buttonVisibility) }
            <Collapse in={openMain}>
                <div className="main-nav menu-container">
                    {children}
                </div>
            </Collapse>
            </React.Fragment>
            )
    }
    else {
        // affichage du bouton seul
        return (
            <React.Fragment>
                { displayButton(nameNav, iconStyle, icon, buttonVisibility) }
            </React.Fragment>
        )
    }
}


// Redux: mapping action creators - setHomeNav
// Mise à jour de l'état du menu de la page d'accueil
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        setOpenMain: setOpenMain,
        setNameMain: setNameMain,
        setOpenSub: setOpenSub,
        setNameSub: setNameSub
    }, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = (state) => {
    return {
        openMain: state.openMain,
        nameMain: state.nameMain
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNav)

