import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Package Admin Travelbook
import './AdminTravelbook.scss'

// import logo chargement
import logo from '../../logo.svg';

// composants
import TravelMap from './TravelMap'
import TravelPanel from './TravelPanel'

// actions redux
import { setTravelbooks } from '../../actions/index'
import { setCurrentTravelbook } from '../../actions/index'
import { setSteps } from '../../actions/index'
import { setNoteIndexes } from '../../actions/index'
import { setCurrentStep } from '../../actions/index'
import { setTracks } from '../../actions/index'
import { setSelectedStepId } from '../../actions/index'

// constantes
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'


// Page administration des élements du carnet de voyage courant
// mise à jour de la liste des carnets de voyage
// modification / suppression du carnet de voyage
// ajout / modification / suppression d'une etape
// ajout / modification/ suppression d'une note
// mise à jour de la liste des etapes & notes du carnet de voyage
// affichage de la carte


const AdminTravelbook = (props) => {

  // container racine pour l'administration d'un carnet de voyage
  // Mise à jour des variables globales
  const { travelbookSlug, travelbooks, setTravelbooks, setCurrentTravelbook, setCurrentStep, steps, setSteps, setNoteIndexes, setTracks, selectedStepId, setSelectedStepId } = props

  // booleen chargement du carnet de voyage
  const [ loaded, setLoaded ] = React.useState( false )

  // lecture de la liste des carnets de voyage (entete) et du carnet de voyage courant
  React.useEffect(() => {

    const fetchData = async () => {
      try {

        // chargement de la liste des carnets de voyage si on ne vient pas de la page d'accueil
        if (!travelbooks.length) {
          const data = new FormData()
          data.append('action', 'get-travelbooks')
          const result = await axios.post( TRAVELSTEPS_SERVER_URL, data )
          // mise à jour de la liste des carnets de voyage
          setTravelbooks(result.data)
        }

        // recherche et lecture du carnet de voyage (etapes et notes comprises)
        let data = new FormData()
        data.append('action', 'get-travelbook-data')
        data.append('travelbookSlug', travelbookSlug)
        let result = await axios.post( TRAVELSTEPS_SERVER_URL, data )
        
        // mise à jour du carnet de voyage courant
        const newCurrentTravelbook = result.data.travelbook
        setCurrentTravelbook(newCurrentTravelbook)

        // mise à jour de la liste des étapes
        const newSteps = result.data.steps
        if (newSteps && newSteps.length) {
          setSteps(newSteps)
        }

        // mise à jour de la liste des indexes de note
        const newNoteIndexes = result.data.noteIndexes
        setNoteIndexes(newNoteIndexes)
             

        // recherche des fichiers traces du carnet de voyage courant
        data = new FormData()
        data.append('action', 'get-tracks')
        data.append('travelbookId', newCurrentTravelbook.id)
        result = await axios.post( TRAVELSTEPS_SERVER_URL, data )
        // mise à jour de la liste des traces
        setTracks(result.data)
        
        // mise à jour du booleen chargement des données
        setLoaded(true)

      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
    
    // eslint-disable-next-line
  }, [])

  
  // Désignation d'une étape sur la carte
  React.useEffect(() => {
    
    if (selectedStepId) {
      // la désignation d'une étape sur la carte entraine un changement d'étape courante
      const newCurrentStep = steps.find(step => step.id === selectedStepId)
      setCurrentStep(newCurrentStep)
      // raz de la selection précédente
      setSelectedStepId('')
    }

    // eslint-disable-next-line
  }, [selectedStepId])


  if ( !loaded ) return ( <div className="loadingV"><p><strong>Chargement du carnet de voyage ...</strong></p><img src={logo} className="App-logo" alt="logo" /></div> )
  else return (
    <div className="admin-currenttravelbook tsp-box">
      <TravelPanel></TravelPanel>
      <TravelMap></TravelMap>
    </div>
  )
}

// Redux: mapping action creators 
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    setTravelbooks:setTravelbooks, // liste des carnets de voyage
    setCurrentTravelbook: setCurrentTravelbook, // carnet de voyage courant
    setSteps: setSteps, // liste des étapes
    setNoteIndexes: setNoteIndexes, // liste des index de note
    setCurrentStep: setCurrentStep, // etape courante
    setTracks: setTracks,  // liste des traces
    setSelectedStepId: setSelectedStepId  // etape sélectionnée sur la carte
  }, dispatch)
}

const mapStateToProps = state => {
  return { 
    travelbooks: state.travelbooks,
    steps: state.steps,
    selectedStepId: state.selectedStepId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTravelbook)