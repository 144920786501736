import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// protection attaque xss
import dompurify from 'dompurify';

// image loader & resizer
import { readAndCompressImage } from 'browser-image-resizer'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { QUALITY_IMAGE, MAXWIDTH_IMAGE, MAXHEIGHT_IMAGE } from '../../commons.js'

// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'

// action redux liste des étapes
import { setSteps } from '../../actions/index'
// action redux note courante
import { setCurrentNote } from '../../actions/index'


// Menu Ajout d'une note à l'étape courante
// Les champs du formulaire sont synchronisés sur la variable globale formState
// L'ajout d'une note modifie la variable gloable steps

const NoteAddNav = (props) => {

    const { setOpenMain, setOpenSub, currentTravelbook, currentStep, currentNote, setCurrentNote, steps, setSteps, noteIndexes } = props

    const emptyForm = {
        id: '',
        description: '',    // plain-text ou Html
        textfirst: true,    // texte en premier par défaut
        caption: '',        // legende du media (image/video/audio/gpx)
        visibility: true
    }

    const emptyImageData = { blob: null, base64: null }

    // on mémorise les items saisis via le formulaire
    const [formState, setFormState] = React.useState(emptyForm)
    const { description, textfirst, caption, visibility } = formState

    // on mémorise les données du fichier image
    const [imageFilename, setImageFilename] = React.useState('')
    const [imageData, setImageData] = React.useState(emptyImageData)

    // on mémorise les données du fichier video
    const [videoFilename, setVideoFilename] = React.useState('')
    const [videoData, setVideoData] = React.useState(null)

    // on mémorise les données du fichier audio
    const [audioFilename, setAudioFilename] = React.useState('')
    const [audioData, setAudioData] = React.useState(null)

    // on mémorise les données du fichier gpx
    const [gpxFilename, setGpxFilename] = React.useState('')
    const [gpxData, setGpxData] = React.useState(null)

    // message d'erreur
    const [msgErr, setMsgErr] = React.useState('')

    // booleen initialisation du formulaire
    const [formReady, setFormReady] = React.useState(false)

    const imageInput = React.createRef()
    const videoInput = React.createRef()
    const audioInput = React.createRef()
    const gpxInput = React.createRef()


    // gestion de la modification des champs du formulaire
    const onChangeField = (e) => {
        const valfield = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
        setFormState({ ...formState, [e.target.name]: valfield })
    }

    // simulation du click sur le champ nom des fichiers
    const onClickImage = () => {
        imageInput.current.click()
    }
    const onClickVideo = () => {
        videoInput.current.click()
    }
    const onClickGpx = () => {
        gpxInput.current.click()
    }
    const onClickAudio = () => {
        audioInput.current.click()
    }

    // conversion Blob to Base64 pour une image
    const convertToBase64 = (blob) => {
        return new Promise(resolve => {
            var reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    }

    // modification de l'image
    const onChangeImage = async (e) => {

        e.preventDefault()

        const config = {
            quality: QUALITY_IMAGE,
            maxWidth: MAXWIDTH_IMAGE,
            maxHeight: MAXHEIGHT_IMAGE,
            autoRotate: true,
            debug: false
        }

        const imgfile = e.target.files[0].name
        // lecture et redimensionnement de l'image
        let blobImage = await readAndCompressImage(
            e.target.files[0],
            config
        )
        // conversion base64
        let base64Image = await convertToBase64(blobImage)
        // memorisation des données de l'image
        setImageData({ blob: blobImage, base64: base64Image })
        // mise à jour du nom du fichier image
        setImageFilename(imgfile)
    }

    // modification du fichier video
    const onChangeVideo = async (e) => {
        e.preventDefault()
        setVideoFilename(e.target.files[0].name)  // nom du fichier video
        setVideoData(e.target.files[0])           // contenu du fichier
    }

    // modification du fichier gpx
    const onChangeGpx = async (e) => {
        e.preventDefault()
        setGpxFilename(e.target.files[0].name)  // nom du fichier gpx
        setGpxData(e.target.files[0])           // contenu du fichier
    }

    // modification du fichier audio
    const onChangeAudio = async (e) => {
        e.preventDefault()
        setAudioFilename(e.target.files[0].name)    // nom du fichier audio
        setAudioData(e.target.files[0])             // contenu du fichier
    }

    // mise à zero du formulaire
    const eraseForm = () => {
        setFormState(emptyForm)
        setImageFilename('')
        setImageData(emptyImageData)
        setVideoFilename('')
        setVideoData(null)
        setAudioFilename('')
        setAudioData(null)
        setGpxFilename('')
        setGpxData(null)
    }

    // effacement des données du formulaire
    const onClickErase = (e) => {
        e.preventDefault()
        eraseForm()
    }

    // ajout d'une note
    const onClickAdd = (e) => {

        e.preventDefault()

        if (!formState.id) {

            // determination du type de note
            let mediatype = ''
            if (imageFilename) mediatype = 'image'
            else if (videoFilename) mediatype = 'video'
            else if (audioFilename) mediatype = 'audio'
            else if (gpxFilename) mediatype = 'gpx'

            if (mediatype || description) {

                // protection xss
                const safeDescription = dompurify.sanitize(description)

                let number = 1
                if (currentStep.notes.length) {
                    // il existe déjà une note pour cette étape
                    number = Number(currentNote.number) + 1 // insertion de la note après la note courante
                }

                let postData = ({
                    action: 'add-note', travelbookSlug: currentTravelbook.slug, stepId: currentStep.id,
                    ...formState, description: safeDescription, number: number
                })

                postData.filename = ''  // nom de fichier par defaut
                if (mediatype) {
                    // ajout du type de media
                    postData.mediatype = mediatype
                    // ajout du nom de fichier et des donnees
                    switch (mediatype) {
                        case 'image':
                            if (imageData.blob) {
                                postData.filename = imageFilename
                                postData.fileData = imageData.blob
                            }
                            break
                        case 'video':
                            if (videoData.blob) {
                                postData.filename = videoFilename
                                postData.fileData = videoData.blob
                            }
                            break
                        case 'audio':
                            if (audioData.blob) {
                                postData.filename = audioFilename
                                postData.fileData = audioData.blob
                            }
                            break
                        case 'gpx':
                            if (gpxData.blob) {
                                postData.filename = gpxFilename
                                postData.fileData = gpxData.blob
                            }
                            break
                        default:
                            break
                    }
                }

                const data = new FormData()
                for (const property in postData) {
                    data.append(property, postData[property])
                }

                axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(function (response) {
                        
                        const addedNote = { ...formState, id: response.data.id, filename: postData.filename, mediatype: mediatype, description: safeDescription, stepId: currentStep.id, number: number }

                        // mise à jour de la liste des notes
                        let indexpp = 0;
                        const newNotes = [...currentStep.notes]
                        if (currentStep.notes.length) {
                            // mise à jour de la liste des notes - insertion après la note courante
                            const indexp = newNotes.findIndex(note => note.id === currentNote.id)   // index de la note precedente
                            newNotes.splice(indexp + 1, 0, addedNote)                 // insertion de la note après la note courante
                            for (let i = indexp + 2; i < newNotes.length; i++) {
                                newNotes[i].number = Number(newNotes[i].number) + 1     // increment du numero d'ordre des notes suivantes
                            }
                            // recherche de l'indice de la note précédente dans noteIndexes
                            const previousNoteId = newNotes[indexp].id
                            indexpp = noteIndexes.findIndex(noteIndex => noteIndex[0] === previousNoteId) // recherche de la note precedente
                        }
                        else {
                            // premiere note ajoutée depuis le menu current Step
                            newNotes.splice(0, 0, addedNote)
                            // recherche de l'indice de la note précédente dans noteIndexes
                            if (currentStep.previousId) {
                                // il existe une etape precedente
                                const indexps = steps.findIndex(step => step.id === currentStep.previousId) 
                                const nbn = steps[indexps].notes.length
                                const previousNote = steps[indexps].notes[nbn-1]
                                indexpp = noteIndexes.findIndex(noteIndex => noteIndex[0] === previousNote.id) // recherche de la note precedente
                            }
                            else {
                                indexpp = -1     // premiere note du carnet
                            }
                        }

                        // mise à jour de la liste des étapes avec la nouvelle note
                        const index = steps.findIndex(step => step.id === currentStep.id) // index de l'étape courante
                        const newSteps = [...steps]
                        newSteps[index].notes = newNotes

                        // mise à jour de la liste des index de notes
                        const newNoteIndex = [ addedNote.id, addedNote.stepId ]
                        noteIndexes.splice(indexpp + 1, 0, newNoteIndex) // insertion dans la liste noteIndexes

                        // mise à jour de l'indice de la premiere note
                        if (addedNote.number === 1) {
                            newSteps[index].firstnote = indexpp + 1 // mise à jour de l'index de la première note
                            for(let i=index+1; i < newSteps.length; i++) {
                                newSteps[i].firstnote = newSteps[i].firstnote + 1   // décalage des index de premiere note suivants
                            }
                        }

                        // mise à jour de la liste des étapes
                        setSteps(newSteps)

                        // effacement des données du formulaire
                        eraseForm()
                        setMsgErr('')
                        // fermeture des menus principal et secondaire
                        setOpenMain(false)
                        setOpenSub(false)
                        // devient la note courante
                        setCurrentNote(addedNote)
                    })
                    .catch(function (error) {
                        console.log(error)
                        setMsgErr('>>> Ajout en erreur.')
                    })

            }
        }
    }


    // initialisation du formulaire
    React.useEffect(() => {

        const setFormData = async () => {
            try {
                // mise à zero du formulaire
                eraseForm()
                setMsgErr('')
                setFormReady(true)
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Ajout en erreur.')
            }
        }

        setFormData()

        // eslint-disable-next-line
    }, [currentNote])



    if (!formReady) return ('')
    else return (
        <div className="add-note-container container no-gutters">
            <p><strong>Ajout d'une note </strong></p>
            <form>
                <div className="form-group row">
                    <div className="col-2">
                        <label htmlFor="addNoteDesc" className="col-form-label col-form-label-sm">Texte: </label>
                        <br /><br />
                        <div className="textfirst-container form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="addNoteTextFirst" name="textfirst" checked={textfirst} onChange={onChangeField} />
                            <label className="form-check-label" htmlFor="addNoteTextFirst">En premier</label>
                        </div>
                    </div>
                    <div className="col-10">
                        <textarea id="addNoteDesc" className="form-control form-control-sm" name="description" cols="40" rows="5" value={description} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addNoteImage" className="col-2 col-form-label col-form-label-sm">Image: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="addNoteImage" name="image" readOnly value={imageFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="addNoteImageName" name="imageName" ref={imageInput} onChange={onChangeImage} accept="image/*" />
                    <div className="col-3">
                        <button type="button" onClick={onClickImage} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive" src={imageData.base64} alt="" />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addNoteTitle" className="col-2 col-form-label col-form-label-sm">Titre: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="addNoteTitre" name="caption" value={caption} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addNoteVideo" className="col-2 col-form-label col-form-label-sm">Video: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="addNoteVideo" name="video" readOnly value={videoFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="addNoteVideoName" name="videoName" ref={videoInput} onChange={onChangeVideo} accept="video/*" />
                    <div className="col-3">
                        <button type="button" onClick={onClickVideo} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addNoteGpx" className="col-2 col-form-label col-form-label-sm">Gpx: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="addNoteGpx" name="gpx" readOnly value={gpxFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="addNoteGpxName" name="gpxName" ref={gpxInput} onChange={onChangeGpx} accept=".gpx" />
                    <div className="col-3">
                        <button type="button" onClick={onClickGpx} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm">Audio: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="addNoteAudio" name="audio" readOnly value={audioFilename} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="addNoteAudioName" name="audioName" ref={audioInput} onChange={onChangeAudio} accept="audio/*" />
                    <div className="col-3">
                        <button type="button" onClick={onClickAudio} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                </div>

                <div className="visibility-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="addNoteVisibility" name="visibility" checked={visibility} onChange={onChangeField} />
                    <label className="form-check-label" htmlFor="addNoteVisibility">Note visible</label>
                </div>

                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickErase} className="btn btn-primary btn-sm">Effacer</button>
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={onClickAdd} className="btn btn-primary btn-sm">Ajouter</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators - setSteps / setOpenMain / setCurrentStep
// Mise à jour de la liste des étapes, de la nouvelle étape et du menu étapes
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub,
        setSteps: setSteps,
        setCurrentNote: setCurrentNote
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        currentNote: state.currentNote,
        steps: state.steps,
        noteIndexes: state.noteIndexes
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteAddNav)