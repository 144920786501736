import React from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

// action redux menu page accueil
import { setOpenSub } from "../../actions/index"
import { setNameSub } from "../../actions/index"

const MainNavItem = (props) => {

    const { nameNav, nameSub, setNameSub, openSub, setOpenSub, children } = props

    const getSubNav = (e, nameNav) => {
        e.preventDefault()

        if (!openSub || (nameNav === nameSub)) setOpenSub(!openSub)    // ouverture/fermeture du menu secondaire
        setNameSub(nameNav)     // nom du menu secondaire à charger
    }

    return (
        <button
            type="button"
            className="link-button"
            onClick={(e) => getSubNav(e, nameNav)}
        >
            { children }
        </button>
    )


}

// Redux: mapping action creators - setHomeNav
// Mise à jour de l'état du menu de la page d'accueil
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        setOpenSub: setOpenSub,
        setNameSub: setNameSub
    }, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = (state) => {
    return {
        openSub: state.openSub,
        nameSub: state.nameSub
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavItem)