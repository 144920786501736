import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DatePicker from 'react-date-picker'
import { readAndCompressImage } from 'browser-image-resizer'

// protection attaque xss
import dompurify from 'dompurify';

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { QUALITY_IMAGE, MAXWIDTH_IMAGE, MAXHEIGHT_IMAGE } from '../../commons.js'

// action redux liste des étapes
import { setSteps } from '../../actions/index'
// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'
// action redux étape courante
import { setCurrentStep } from '../../actions/index'
// action redux selection d'un point sur la carte
import { setSelectedPoint } from '../../actions/index'


// Menu Ajout d'une étape
// Les champs du formulaire sont synchronisés sur la variable globale formState
// L'ajout d'une étape modifie la variable gloable steps

const StepAddNav = (props) => {

    const { currentTravelbook, currentStep, steps, setSteps, setOpenMain, setOpenSub, setCurrentStep, noteIndexes, setSelectedPoint, selectedPoint } = props

    const emptyForm = {
        id: '',
        slug: '',
        title: '',
        description: '',
        eventdate: '',
        image: '',      // nom du fichier image
        latlng: '',
        previousId: 0,
        nextId: 0,
        visibility: true,
        createnote: false,
        notes: []
    }

    // on mémorise l'item saisi via le formulaire
    const [formState, setFormState] = React.useState(emptyForm)
    const { slug, title, description, eventdate, image, latlng, visibility, createnote } = formState

    // on mémorise les données de l'image
    const [imageData, setImageData] = React.useState({ blob: null, base64: null })

    // message d'erreur
    const [ msgErr, setMsgErr ] = React.useState('') 

    // booleen initialisation du formulaire
    const [formReady, setFormReady] = React.useState(false)
 
    
    // gestion de la modification des champs du formulaire
    const onChangeField = (e) => {
        const valfield = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
        setFormState({ ...formState, [e.target.name]: valfield })
    }

    // Mise à jour du champ latlng avec la localisation courante
    const setCurrentPosition = (e) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                const latlng = position.coords.latitude.toFixed(6) + ',' + position.coords.longitude.toFixed(6)
                setSelectedPoint(latlng)
            })
        }
    }

    // simulation du click
    const getFile = () => {
        fileInput.current.click()
    }

    // conversion Blob to Base64 pour une image
    const convertToBase64 = (blob) => {
        return new Promise(resolve => {
            var reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    }

    // modification de l'image
    const onChangeImage = async (e) => {

        e.preventDefault()

        const config = {
            quality: QUALITY_IMAGE,
            maxWidth: MAXWIDTH_IMAGE,
            maxHeight: MAXHEIGHT_IMAGE,
            autoRotate: true,
            debug: false
        }

        const file = e.target.files[0].name
        // lecture et redimensionnement de l'image
        let blobImage = await readAndCompressImage(
            e.target.files[0],
            config
        )
        // conversion base64
        let base64Image = await convertToBase64(blobImage)
        // memorisation des données de l'image
        setImageData({ blob: blobImage, base64: base64Image })
        // mise à jour du nom de fichier
        setFormState( { ...formState, image: file } )
    }

    // effacement des données du formulaire
    const onClickErase = (e) => {
        e.preventDefault()
        setFormState({ emptyForm, image: '' })
        setImageData({ blob: null, base64: null })
    }


    // ajout d'une étape
    const onClickAdd = (e) => {

        e.preventDefault()

        if (formState.title) {
            // conversion préalable des dates en chaine de caractères
            let previousId = 0
            if (currentStep) previousId = currentStep.id
            let nextId = 0
            if (currentStep) nextId = currentStep.nextId

            // conversion préalable de la date en chaine de caractère
            let eventdateString = ''
            if (formState.eventdate) eventdateString = formState.eventdate.toISOString()

            // protection xss
            const safeDescription = dompurify.sanitize(description)

            //  (post - add-step) - envoi des données du formulaire + blob image vers le serveur
            let postData = ({ action: 'add-step', ...formState, description: safeDescription, travelbookId: currentTravelbook.id, travelbookSlug: currentTravelbook.slug, 
                previousId: previousId, nextId: nextId, eventdate: eventdateString })
            // ajout de l'image si nécessaire
            if ( imageData.blob ) postData.imageData = imageData.blob

            const data = new FormData()
            for (const property in postData) {
                data.append(property, postData[property])
            }

            axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(function (response) {
                    // en retour on récupère les proprietes id, slug, previousId et nextId
                    const addedStep = { ...formState, id: response.data.id, slug: response.data.slug, description: safeDescription, previousId: previousId, nextId: nextId }

                    // chainage des étapes
                    let newSteps = []
                    if (steps.length) {
                        newSteps = [ ...steps ]
                        // mise à jour de la liste des étapes - insertion après l'étape courante
                        const indexp = steps.findIndex( step => step.id === previousId )   // index de l'étape precedente
                        newSteps.splice( indexp + 1, 0, addedStep )             // insertion de l'etape après l'étape courante
                        newSteps[indexp].nextId = addedStep.id                  // chainage suivant de l'étape precedent
                        newSteps[indexp + 1].nextId = nextId                    // chainage suivant
                        newSteps[indexp + 1].previousId = previousId            // chainage precedent
                        if (nextId) {
                            const indexs = steps.findIndex( step => step.id === nextId )       // index de l'étape suivante
                            newSteps[indexs].previousId = addedStep.id          // chainage precedente de l'étape suivante
                        }
                    }
                    else {
                        // insertion de la premiere etape depuis le menu travelbook
                        newSteps = [ addedStep ]  
                    }

                    if (createnote && response.data.noteId) {   // creation conjuguée d'une première note de type text ou textimage

                        const mediatype = ( image ) ? 'image' : ''
                        const addedNote = { id: response.data.noteId, description: safeDescription, filename: image, mediatype: mediatype, stepId: response.data.id, number: 1 }
                        const newNotes = []
                        newNotes.splice(0, 0, addedNote)

                        const index = newSteps.findIndex( step => step.id === response.data.id )   // index de l'étape créée
                        newSteps[index].notes = newNotes

                        // recherche de l'indice de la note précédente dans noteIndexes
                        let indexpp = 0
                        if (previousId) {
                            // il existe une etape precedente
                            const indexps = steps.findIndex(step => step.id === previousId)
                            const nbn = steps[indexps].notes.length
                            const previousNote = steps[indexps].notes[nbn - 1]
                            indexpp = noteIndexes.findIndex(noteIndex => noteIndex[0] === previousNote.id) // recherche de la note precedente
                        }
                        else {
                            indexpp = -1     // premiere note du carnet
                        }
                        
                        // mise à jour de la liste des index de notes
                        const newNoteIndex = [ addedNote.id, addedNote.stepId ]
                        noteIndexes.splice(indexpp + 1, 0, newNoteIndex) // insertion dans la liste noteIndexes

                        // mise à jour de l'indice de la premiere note
                        if (addedNote.number === 1) {
                            newSteps[index].firstnote = indexpp + 1 // mise à jour de l'index de la première note
                            for(let i=index+1; i < newSteps.length; i++) {
                                newSteps[i].firstnote = newSteps[i].firstnote + 1   // décalage des index de premiere note suivants
                            }
                        }
                    }

                    // mise à jour de la liste des étapes
                    setSteps(newSteps)

                    // effacement des données du formulaire
                    setFormState({ ...emptyForm, image: '' })
                    setImageData({ blob: null, base64: null })
                    setMsgErr('')
                    // fermeture du menu principal et secondaire
                    setOpenMain(false)
                    setOpenSub(false)
                    // devient l'étape courante
                    setCurrentStep(addedStep)
                })
                .catch(function (error) {
                    console.log(error)
                    setMsgErr('>>> Ajout en erreur.')
                })
        }
        else {
            setMsgErr('>>> Un titre est nécessaire.')
        }
    }

    const fileInput = React.createRef()

    // initialisation du formulaire
    React.useEffect(() => {
    
        const setFormData = async () => {
            try {
                // mise à zero du formulaire
                setFormState(emptyForm)
                // effacement image
                setImageData({ blob: null, base64: null })
                // initialisation avec la localisation courante
                setCurrentPosition()
                setMsgErr('')
                setFormReady(true)
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Ajout en erreur.')
            }
        }

        setFormData()

       // eslint-disable-next-line
    }, [currentStep])


    // // mise à jour du formulaire avec le point sélectionné sur la carte
    React.useEffect(() => {
        // mise à jour du champ position
        if (selectedPoint) {
            setFormState({ ...formState, latlng: selectedPoint })
        }

        // eslint-disable-next-line
    }, [selectedPoint])   // la selection d'un point sur la carte met à jour le formulaire
    
    
    if (!formReady) return ('')
    else return (
        <div  className="add-step-container container no-gutters">
            <p><strong>Ajout d'une étape </strong></p>

            <form>
                <div className="visibility-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="addStepCreatenote" name="createnote" checked={createnote} onChange={onChangeField} />
                    <label className="form-check-label" htmlFor="addStepCreatenote">Avec création d'une note</label>
                </div>

                <div className="form-group row">
                    <label htmlFor="addStepSlug" className="col-2 col-form-label col-form-label-sm">Slug: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="addStepSlug" name="slug" placeholder="Identifiant" value={slug} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addStepTitle" className="col-2 col-form-label col-form-label-sm">Titre: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="addStepTitle" name="title" placeholder="Titre" value={title} onChange={onChangeField} />
                    </div>
                </div>
                <label htmlFor="addStepDesc" className="col-form-label col-form-label-sm">Description: </label>
                <div className="form-group row">
                    <div className="col-12">
                        <textarea id="addStepDesc" className="form-control form-control-sm" name="description" cols="40" rows="5" placeholder="Description" value={description} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addStepCoord" className="col-2 col-form-label col-form-label-sm">Coord.: </label>
                    <div className="col-8">
                        <input type="text" className="form-control form-control-sm" id="addStepCoord" name="latlng" placeholder="Coordonnées" readOnly value={latlng} />
                    </div>
                    <div>
                        <button type="button" onClick={setCurrentPosition} className="location" ><span className="icon-location"><i className="far fa-compass"></i></span></button>
                    </div>                
                </div>

                <div className="form-group row">
                    <label htmlFor="addStepDate" className="col-2 col-form-label col-form-label-sm">Date: </label>
                    <div className="col-10">
                        <DatePicker value={eventdate} onChange={(eventdate) => setFormState({ ...formState, eventdate: eventdate })} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addStepImage" className="col-2 col-form-label col-form-label-sm">Image: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="addStepImage" name="image" readOnly value={image} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="addStepFileName" name="fileName" ref={fileInput} onChange={onChangeImage} accept="image/*" />
                    <div className="col-3">
                        <button type="button" onClick={getFile} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive" src={imageData.base64} alt="" />
                    </div>
                </div>

                <div className="visibility-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="addStepVisibility" name="visibility" checked={visibility} onChange={onChangeField} />
                    <label className="form-check-label" htmlFor="addStepVisibility">Etape visible</label>
                </div>

                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickErase} className="btn btn-primary btn-sm">Effacer</button>
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={onClickAdd} className="btn btn-primary btn-sm">Ajouter</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators
// Mise à jour de la liste des étapes, de la nouvelle étape et du menu étapes
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSteps: setSteps,
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub,
        setCurrentStep: setCurrentStep,
        setSelectedPoint: setSelectedPoint
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        steps: state.steps,
        noteIndexes: state.noteIndexes,
        selectedPoint: state.selectedPoint
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepAddNav)