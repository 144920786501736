import React from "react"
import { connect } from 'react-redux'

import Note from "./Note"

// affichage de l'étape dans la scroll liste
const Step = (props) => {

  // étape a afficher
  const { step, stepIndex, currentStep, steps, observer } = props


  const getStepClassName = (stepId) => {
    if (currentStep && (stepId === currentStep.id)) return('step current-step')
    else return('step')
  }

  return (
    <div id={`step-${step.id}`} className={ getStepClassName(step.id) }>
      { steps[stepIndex].notes.map((note) => {
        return (
          <Note key={`note-${note.id}`} stepId={step.id} note={note} observer={observer}/>
        )
      }) }
    </div>
  )
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return { 
    currentStep: state.currentStep,
    steps: state.steps
  }
}

export default connect(mapStateToProps)(Step)