import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { IMAGE_BASE_URL } from '../../commons'

// action redux liste des étapes
import { setSteps } from '../../actions/index'
// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'
// action redux étape courante
import { setCurrentStep } from '../../actions/index'

const StepDeleteNav = (props) => {
    
    const { currentTravelbook, currentStep, setCurrentStep, setOpenMain, setOpenSub, steps, setSteps } = props

    const { title, description } = currentStep

    // on mémorise l'image saisie via le formulaire
    const imageData0 = { src: '' }
    const [imageData, setImageData] = React.useState(imageData0)

    // message d'erreur
    const [ msgErr, setMsgErr ] = React.useState('') 

    // booleen chargement du carnet de voyage
    const [formReady, setFormReady] = React.useState(false)

    // suppression de l'étape
    const onClickDelete = (e) => {

        e.preventDefault()

        if (currentStep.notes.length) {
            setMsgErr('>>> Suppression impossible. L\'étape comporte encore des notes.')
        }
        else {
            // envoi des données vers le serveur pour pour suppression de l'étape
            const data = new FormData()
            data.append('action', 'delete-step')
            data.append('id', currentStep.id)
            data.append('previousId', currentStep.previousId)
            data.append('nextId', currentStep.nextId)

            axios.post(TRAVELSTEPS_SERVER_URL, data)
                .then(function (response) {
                    // mise à jour de la liste des étapes
                    const newSteps = [ ...steps ]
                    const index = newSteps.findIndex( step => step.id === currentStep.id)
                    // suppression de l'étape de la liste
                    if (currentStep.previousId) {
                        // étape précédente
                        const indexp = newSteps.findIndex( step => step.id === currentStep.previousId)
                        newSteps[indexp].nextId = newSteps[index].nextId
                    }
                    if (currentStep.nextId) {
                        // étape suivante
                        const indexs = newSteps.findIndex( step => step.id === currentStep.nextId)
                        newSteps[indexs].previousId = newSteps[index].previousId
                    }
                    newSteps.splice(index, 1)
                    // mise à jour de la liste
                    setSteps(newSteps)

                    // positionnement de la nouvelle étape courante
                    if (index) {
                        setCurrentStep(newSteps[index-1])
                    }
                    else if (newSteps.length) {
                        setCurrentStep(newSteps[index])
                    }
                    else {
                        const newStep = null
                        setCurrentStep(newStep)
                    }
                    // fermeture des menus principal et secondaire
                    setOpenMain(false)
                    setOpenSub(false)
                })
                .catch(function (error) {
                    console.log(error)
                    setMsgErr('>>> Suppression en erreur.')
                })
        }
    }

    // chargement des données du formulaire
    React.useEffect(() => {
    
        const setFormData = async () => {
            try {
                // chargement de l'image à la une si elle a été renseignée
                if (currentStep.image) {
                    const url = IMAGE_BASE_URL + '/' + currentTravelbook.slug + '/image/' + currentStep.image
                    // memorisation des données de l'image - image deja sur le serveur
                    setImageData({ blob: null, base64: null, src: url })
                }
                else {
                    setImageData(imageData0)
                }
                setMsgErr('')
                setFormReady(true)
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Modification en erreur.')
            }
        }

        setFormData()

       // eslint-disable-next-line
    }, [currentStep])

    // // mise à jour du menu Etape courante
    React.useEffect(() => {
        
        if (currentStep) setFormReady(true)
        else setFormReady(false)

        // eslint-disable-next-line
    }, [currentStep])   // la selection d'un point sur la carte met à jour le formulaire


    if (!formReady) return ('')
    else return (
        <div className="update-step-container container no-gutters">
            <p><strong>Suppression d'une étape </strong></p>
            <form>
                <div className="form-group row">
                    <div className="col-3 form-id"> 
                        <input type="text" className="form-control form-control-sm" name="id" readOnly value={currentStep.id} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-12"><h5>{title}</h5></div>
                </div>
                <div className="form-group row">
                    <div className="col-12">{description}</div>
                </div>
                <div className="form-group row">
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive" src={imageData.src} alt="" />
                    </div>
                </div>
                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickDelete} className="btn btn-primary btn-sm">Supprimer</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators
// Mise à jour de la liste des étapes, de la nouvelle étape et du menu étapes
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSteps: setSteps,
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub,
        setCurrentStep: setCurrentStep
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook, 
        currentStep: state.currentStep,
        steps: state.steps
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepDeleteNav)