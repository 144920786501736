// Actions Redux

// Etat de l'application
export const SET_MAPREADY = "SET_MAPREADY"
// Menus
export const SET_NAMEMAIN = "SET_NAMEMAIN"
export const SET_OPENMAIN = "SET_OPENMAIN"
export const SET_NAMESUB = "SET_NAMESUB"
export const SET_OPENSUB = "SET_OPENSUB"
// Courants
export const SET_CURRENTNOTE = "SET_CURRENTNOTE"
export const SET_CURRENTSTEP = "SET_CURRENTSTEP"
export const SET_CURRENTTRACK = "SET_CURRENTTRACK"
export const SET_CURRENTTRAVELBOOK = "SET_CURRENTTRAVELBOOK"
// Listes
export const SET_TRAVELBOOKS = "SET_TRAVELBOOKS"
export const SET_STEPS = "SET_STEPS"
export const SET_NOTEINDEXES = "SET_NOTEINDEXES"
export const SET_TRACKS = "SET_TRACKS"
// Sélections
export const SET_SELECTEDSTEPID = "SET_SELECTEDSTEPID"
export const SET_SELECTEDTRACKID = "SET_SELECTEDTRACKID"
export const SET_SELECTEDPOINT = "SET_SELECTEDPOINT"

export const SET_VIEWSLIDER = "SET_VIEWSLIDER"


// etat de l'application
export function setMapReady(payload) {
    return { type: SET_MAPREADY, payload }
}
// gestion des menus et sous-menus
//nom du menu principal
export function setNameMain(payload) {
    return { type: SET_NAMEMAIN, payload }
}
// ouverture du menu principal
export function setOpenMain(payload) {
    return { type: SET_OPENMAIN, payload }
}
//nom du menu secondaire
export function setNameSub(payload) {
    return { type: SET_NAMESUB, payload }
}
//ouverture du menu secondaire
export function setOpenSub(payload) {
    return { type: SET_OPENSUB, payload }
}

// Note courante
export function setCurrentNote(payload) {
    return { type: SET_CURRENTNOTE, payload }
}
// Trace courante
export function setCurrentTrack(payload) {
    return { type: SET_CURRENTTRACK, payload }
}
// Carnet de voyage courant
export function setCurrentTravelbook(payload) {
    return { type: SET_CURRENTTRAVELBOOK, payload }
}

// Liste des carnets de voyage
export function setTravelbooks(payload) {
    return { type: SET_TRAVELBOOKS, payload }
}
// Liste des étapes du carnet de voyage courant
export function setSteps(payload) {
    return { type: SET_STEPS, payload }
}
// Liste des notes du carnet de voyage courant
export function setNoteIndexes(payload) {
    return { type: SET_NOTEINDEXES, payload }
}
// Liste des traces du carnet de voyage courant
export function setTracks(payload) {
    return { type: SET_TRACKS, payload }
}
// Selection d'une étape sur la carte
export function setSelectedStepId(payload) {
    return { type: SET_SELECTEDSTEPID, payload }
}
// Selection d'une trace sur la carte
export function setSelectedTrackId(payload) {
    return { type: SET_SELECTEDTRACKID, payload }
}
// Selection d'un point sur la carte
export function setSelectedPoint(payload) {
    return { type: SET_SELECTEDPOINT, payload }
}

// Etape courante
function setNewStep(payload) {
    return { type: SET_CURRENTSTEP, payload }
}
// thunk conditionnel - dépend de l'état de l'application
export function setCurrentStep(payload) {
    return function(dispatch, getState) {
        const { currentStep, mapReady } = getState()
        // si la carte n'est pas prête on diffère l'enregistrement de l'étape
        if (( currentStep ) &&  ( !mapReady )) return 
        // Pour la première étape et des que la carte est prete on enregistre les étapes
        dispatch( setNewStep(payload) )
    }
}

// Selection de la vue Slider/ScrollList des etapes
export function setViewSlider(payload) {
    return { type: SET_VIEWSLIDER, payload }
}