import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DatePicker from 'react-date-picker'
import { readAndCompressImage } from 'browser-image-resizer'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { QUALITY_IMAGE, MAXWIDTH_IMAGE, MAXHEIGHT_IMAGE } from '../../commons.js'
import { IMAGE_BASE_URL } from '../../commons.js'

// action redux carnet de voyage courant
import { setCurrentTravelbook } from '../../actions/index'
// action redux liste des carnets de voyage
import { setTravelbooks } from '../../actions/index'
// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'


// menu modification d'un carnet de voyage
const TravelbookUpdateNav = (props) => {

    const emptyForm = {
        id: '',
        slug: '',
        name: '',
        description: '',
        startdate: '',
        enddate: '',
        image: '',
        mkrcolors: ["#0000ff", "#00b2ee", "#ff0000"],
        visibility: false,
    }
    
    const { currentTravelbook, setCurrentTravelbook, travelbooks, setTravelbooks, setOpenMain, setOpenSub } = props

    // on mémorise l'item saisi via le formulaire
    const [formState, setFormState] = React.useState(emptyForm)

    const { id, slug, name, description, startdate, enddate, image, mkrcolors, visibility } = formState

    // on mémorise l'image saisie via le formulaire
    const imageData0 = { blob: null, base64: null, url: '', src: '' }
    const [imageData, setImageData] = React.useState(imageData0)

    const [ msgErr, setMsgErr ] = React.useState('') // message d'erreur

    // booleen chargement du carnet de voyage
    const [formReady, setFormReady] = React.useState(false)


    // gestion de la modification des champs du formulaire
    const onChangeField = (e) => {
        const valfield = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
        setFormState({ ...formState, [e.target.name]: valfield })
    }

    // gestion des dates du formulaire
    const onChangeDate = (date, name) => {
        if (!date) date = '';
        setFormState({ ...formState, [name]: date })
    }

    // changement de couleur
    const onChangeColor = (e, index) => {
        const [vcolor, icolor, ccolor] = mkrcolors;
        let newMkrcolors = []
        const newColor = e.target.value

        if (index === 0) newMkrcolors = [newColor, icolor, ccolor]
        else if (index === 1) newMkrcolors = [vcolor, newColor, ccolor]
        else if (index === 2) newMkrcolors = [vcolor, icolor, newColor]
        else newMkrcolors = [vcolor, icolor, ccolor]

        setFormState({
            ...formState,
            mkrcolors: newMkrcolors
        })
    }

    // simulation du click choisir fichier
    const getFile = () => {
        fileInput.current.click()
    }

    // conversion Blob to Base64 pour une image
    const convertToBase64 = (blob) => {
        return new Promise(resolve => {
            var reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    }

    // modification de l'image
    const onChangeImage = async (e) => {

        const config = {
            quality: QUALITY_IMAGE,
            maxWidth: MAXWIDTH_IMAGE,
            maxHeight: MAXHEIGHT_IMAGE,
            autoRotate: true,
            debug: false
        }

        e.preventDefault()
        const file = e.target.files[0].name
        // lecture et redimensionnement de l'image
        let blobImage = await readAndCompressImage(
            e.target.files[0],
            config
        )
        // conversion base64
        let base64Image = await convertToBase64(blobImage)
        // memorisation des données de l'image
        setImageData({ blob: blobImage, base64: base64Image, url: '', src: base64Image })
        // mise à jour du nom de fichier
        formState.image = file
        setFormState({ ...formState, image: file })
    }

    // effacement des données du formulaire
    const onClickErase = (e) => {
        e.preventDefault()
        setFormState({ ...emptyForm, id: id, slug: slug, image: '' })
        setImageData(imageData0)
    }

    // modification du carnet
    const onClickUpdate = (e) => {

        e.preventDefault()

        if (formState.id) {
            // conversion préalable des dates en chaine de caractères
            let startdateString = ''
            if (formState.startdate) startdateString = formState.startdate.toISOString()
            let enddateString = ''
            if (formState.enddate) enddateString = formState.enddate.toISOString()
            const tmpFormState = ({ ...formState, startdate: startdateString, enddate: enddateString })

            //  (post - update-travelbook) - envoi des données du formulaire + blob image vers le serveur
            let postData = null
            if ( !imageData.blob ) postData = ({ action: 'update-travelbook', ...tmpFormState  })
            else postData = ({ action: 'update-travelbook', imageData: imageData.blob, ...tmpFormState  })

            const data = new FormData()
            for (const property in postData) {
                data.append(property, postData[property])
            }

            axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(function (response) {
                    if (response.data) {
                        // mise à jour de la liste des carnets de voyage
                        const newTravelbooks = [ ...travelbooks ]
                        const index = newTravelbooks.findIndex( travelbook => travelbook.slug === currentTravelbook.slug)
                        newTravelbooks[index] = tmpFormState
                        setTravelbooks(newTravelbooks)
                        // mise à jour du carnet de voyage courant
                        setCurrentTravelbook(tmpFormState)
                    }
                    // effacement des données du formulaire
                    setFormState({ ...emptyForm, image: '' })
                    setImageData(imageData0)
                    // fermeture des menus principal et secondaire
                    setOpenMain(false)
                    setOpenSub(false)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const fileInput = React.createRef()

    // chargement des données du formulaire
    React.useEffect(() => {
        const setFormData = async () => {
            try {
                const index = travelbooks.findIndex( travelbook => travelbook.slug === currentTravelbook.slug)
                // conversion des chaines dates au format date pour le date picker
                let startdate = ''
                if (travelbooks[index].startdate !== "0000-00-00 00:00:00") startdate = new Date(travelbooks[index].startdate)
                let enddate = ''
                if (travelbooks[index].enddate !== "0000-00-00 00:00:00") enddate = new Date(travelbooks[index].enddate)
                const newFormState = ({ ...travelbooks[index], startdate: startdate, enddate: enddate })

                // chargement des données du formulaire
                setFormState(newFormState)
                // chargement de l'image à la une si elle a été renseignée
                if (newFormState.image) {
                    const url = IMAGE_BASE_URL + '/' + currentTravelbook.slug + '/image/' + currentTravelbook.image
                    // memorisation des données de l'image - image deja sur le serveur
                    setImageData({ blob: null, base64: null, url: url, src: url })
                }
                else {
                    setImageData(imageData0)
                }
                setMsgErr('')
                setFormReady(true)
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Modification en erreur.')
            }
        }

        setFormData()

        // eslint-disable-next-line
    }, [])

    if (!formReady) return ('')
    else return (
        <div className="update-travelbook-container container no-gutters">
            <p><strong>Modification du carnet de voyage </strong></p>
            <form>
                <div className="form-group row">
                    <label htmlFor="updTbId" className="col-2 col-form-label col-form-label-sm">Id: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="updTbId" name="id" placeholder="Id" readOnly value={id} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="updTbSlug" className="col-2 col-form-label col-form-label-sm">Slug: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="updTbSlug" name="slug" placeholder="Identifiant" value={slug} readOnly onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updTbName" className="col-2 col-form-label col-form-label-sm">Nom: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="updTbName" name="name" placeholder="Nom" value={name} onChange={onChangeField} />
                    </div>
                </div>
                <label htmlFor="updTbDesc" className="col-form-label col-form-label-sm">Description: </label>
                <div className="form-group row">
                    <div className="col-12">
                        <textarea id="updTbDesc" className="form-control form-control-sm" name="description" cols="40" rows="5" placeholder="Description" value={description} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updTbStartDate" className="col-2 col-form-label col-form-label-sm">Début: </label>
                    <div className="col-10">
                    <DatePicker value={startdate} onChange={(date) => onChangeDate(date,'startdate')} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="updTbEndDate" className="col-2 col-form-label col-form-label-sm">Fin: </label>
                    <div className="col-10">
                        <DatePicker value={enddate} onChange={(date) => onChangeDate(date, 'enddate')} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="updTbImageName" className="col-2 col-form-label col-form-label-sm">Image: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="updTbImageName" name="image" readOnly value={image} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="fileName" name="fileName" ref={fileInput} onChange={onChangeImage} accept="image/*" />
                    <div className="col-3">
                        <button type="button" onClick={getFile} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive" src={imageData.src} alt="" />
                    </div>
                </div>

                <fieldset>
                    <p>Couleur des marqueurs</p>
                    <div className="row">
                        <label htmlFor="updTbvColor" className="col-3 col-form-label col-form-label-sm">Visible: </label>
                        <div className="col-3 tcolor">
                            <input type="color" className="form-control form-control-sm" id="updTbvColor" name="vColor" value={mkrcolors[0]} onChange={(e) => onChangeColor(e, 0)} />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="updTbiColor" className="col-3 col-form-label col-form-label-sm">Invisible: </label>
                        <div className="col-3 tcolor">
                            <input type="color" className="form-control form-control-sm" id="updTbiColor" name="iColor" value={mkrcolors[1]} onChange={(e) => onChangeColor(e, 1)} />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="updTbcColor" className="col-3 col-form-label col-form-label-sm">Courant: </label>
                        <div className="col-3 tcolor">
                            <input type="color" className="form-control form-control-sm" id="updTbcColor" name="cColor" value={mkrcolors[2]} onChange={(e) => onChangeColor(e, 2)} />
                        </div>
                    </div>
                </fieldset>

                <div className="visibility-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="updTbVisibility" name="visibility" checked={visibility} onChange={onChangeField} />
                    <label className="form-check-label" htmlFor="updTbVisibility">Carnet visible</label>
                </div>
                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickErase} className="btn btn-primary btn-sm">Effacer</button>
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={onClickUpdate} className="btn btn-primary btn-sm">Modifier</button>
                    </div>
                </div>
            </form>
        </div>
    )

}

// Redux: mapping action creators
// Mise à jour de la liste de carnets et du menu de la page d'accueil
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setTravelbooks: setTravelbooks,
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub,
        setCurrentTravelbook: setCurrentTravelbook
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        travelbooks: state.travelbooks
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelbookUpdateNav)