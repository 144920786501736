import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Carousel from 'react-bootstrap/Carousel'

import Note from '../../components/Note'

// actions redux
import { setCurrentStep } from '../../actions/index'


// Affichage du slider des notes

const NotesCarousel = (props) => {

  // variables d'état gloables
  const { steps, currentStep, setCurrentStep, noteIndexes } = props

  const index0 = (noteIndexes.length) ? noteIndexes.length-1 : 0

  const [index, setIndex] = React.useState(index0)

  // changement de note via les fleches
  const handleSelect = (selectedIndex, e) => {
    // on regarde si l'item affiche correspond à une note d'une nouvelle étape
    const newStepId = noteIndexes[selectedIndex][1]
    if (newStepId !== currentStep.id) {
      // nouvelle étape
      const newStep = steps.find( step => step.id === newStepId )
      if (newStep) setCurrentStep(newStep)
    }
    // maj de l'index
    setIndex(selectedIndex)
  }

  // mise à jour de la position du slider en fonction du marker etape désigné sur la carte
  React.useEffect(() => {

    const updateSlider = async () => {
      const newIndex = currentStep.firstnote  // position sur la premiere note de l'étape
      setIndex(newIndex)
    }

    if (currentStep) updateSlider()

    // eslint-disable-next-line
  }, [currentStep])

  // affichage des notes
  return (
    <div id="steps-slider" className="notes-slider-container">
      <Carousel 
        activeIndex={index} 
        onSelect={handleSelect}
        indicators={false} 
        interval={false}  // false ou 5000
      >
        { steps.map((step) => {
          return step.notes.map((note) => {
            return (
              <Carousel.Item>
                <Note key={`note-${note.id}`} stepId={step.id} note={note} />
              </Carousel.Item>
            )
          })
        }
        ) }
      </Carousel>
    </div>
  )
}

// Redux: mapping action creators 
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    setCurrentStep: setCurrentStep // etape courante
  }, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
    steps: state.steps,
    currentStep: state.currentStep,
    noteIndexes: state.noteIndexes
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesCarousel)