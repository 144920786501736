import React from "react"

// Composants menus
import MainNav from "../Navigation/MainNav"
import MainNavItem from "../Navigation/MainNavItem"
import SubNav from "../Navigation/SubNav"

// Menu ajout d'un carnet de voyage
import TravelbookAddNav from "../TravelbookNav/TravelbookAddNav"

// affichage du nom de l'application et du menu création d'un carnet

const HomeNavBar = (props) => {

    return (
        <div className="home-navbar-container">
            <div className="navbar-container">
                <div className="title">Travelsteps</div>
            </div>
            <MainNav nameNav={'homeNav'}>

                <MainNavItem nameNav={'addTravelbook'}>
                    Ajouter un carnet de voyage
                    </MainNavItem>
                <MainNavItem nameNav={'params'}>
                    Paramètres de l'application
                    </MainNavItem>

            </MainNav>

            <SubNav nameNav={'addTravelbook'}>
                <TravelbookAddNav></TravelbookAddNav>
            </SubNav>
            <SubNav nameNav={'params'}>
                Menu Paramètres de l'application à développer
                </SubNav>
        </div>
    )
}

export default HomeNavBar