import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Step from '../../components/Step'

// actions redux
import { setCurrentStep } from '../../actions/index'

// Affichage de la scroll liste des étapes et des notes

const NotesScrollList = (props) => {

  // variables d'état gloables
  const { currentStep, steps, setCurrentStep, selectedStepId } = props

  // Etape courante précédente
  const [previousCurrentStepId, setPreviousCurrentStepId] = React.useState(0)

  // reference dom de la scrollList
  const scrollRef = React.createRef()

  const zone = document.querySelector('#viewer-zone')
  const observer = new IntersectionObserver(
    function (entries) {

      // declenchement de l'observer - Dés que le div de la première note intersecte le div de la scroll-liste on modifie l'étape courante
      let result = []
      let j = 0
      let noteId = 0
      let stepId = 0
      let ratio = 0.
      for( let i=0; i<entries.length; i++) {
        if( ( entries[i]['isIntersecting'] === true ) && ( entries[i]['intersectionRatio'] >= 0.50) ) {
          // on enregistre toutes les notes qui apparaissent à plus de 50% de la surface du viewer
          ratio = entries[i]['intersectionRatio']
          noteId = Number(entries[i]['target'].id.substring(5))              // note-xx
          stepId = Number(entries[i]['target'].parentNode.id.substring(5))   // step-xx
          result[j] = [ ratio, noteId, stepId]
          j = j + 1
        }
      }
      if (result.length) {
        // on trie le tableau pour ne retenir que la note la plus visible
        result.sort((a,b) => b[0] - a[0])
        //const noteIdSelected = result[0][1]
        const stepIdSelected = result[0][2]
        // recherche de l'étape
        const index = steps.findIndex(step => step.id === stepIdSelected) 
        // mise à jour de l'étape courante
        setCurrentStep(steps[index])
      }

    }, { root: zone, threshold: [0.5, 0.75, 1.], rootMargin: "0px 0px 0px 0px" } // dés que la première note est affichée à 50%
  )


  // mise à jour de la position de la scrollList en fonction du marker etape désigné sur la carte
  React.useEffect(() => {
    const updateScrollList = async () => {
      if (previousCurrentStepId !== selectedStepId) {
          // mise à jour de la position du scrollbar suite à la designation d'un marker
          const stepElt = document.getElementById(`step-${selectedStepId}`)
          if (stepElt) {
            scrollRef.current.scrollTop = stepElt.offsetTop - scrollRef.current.offsetTop - 10
            setPreviousCurrentStepId(selectedStepId)
          }
      }
    }

    if (currentStep) updateScrollList()

    // eslint-disable-next-line
  }, [selectedStepId])


  // affichage des etapes
  return (
    <div ref={scrollRef} id="notes-scrolllist" className="notes-scrlist-container">
      {steps.map((step, index) => {
        return <Step key={`step-${step.id}`} step={step} stepIndex={index} observer={observer} />
      })}
    </div>
  )
}

// Redux: mapping action creators 
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCurrentStep: setCurrentStep, // etape courante
  }, dispatch)
}

// Redux: mapping action creators - setCurrentStep / setScrollReady
// Mise à jour de l'étape courante
// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
    currentStep: state.currentStep,
    currentNote: state.currentNote,
    steps: state.steps,
    selectedStepId : state.selectedStepId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesScrollList)