import { SET_NAMEMAIN } from "../actions/index"

// Mise à jour de l'état du menu la page d'accueil
const nameMainReducer = (state = '', action) => {
  if (action.type === SET_NAMEMAIN) {
    return action.payload
  }
  return state
}

export default nameMainReducer
