import React from 'react'
import { connect } from 'react-redux'

// Composants menus
import MainNav from "../Navigation/MainNav"
import MainNavItem from "../Navigation/MainNavItem"
import SubNav from "../Navigation/SubNav"

// sous-menu ajout d'une note
import NoteAddNav from '../NoteNav/NoteAddNav'
// sous-menu modification d'une note
import NoteUpdateNav from '../NoteNav/NoteUpdateNav'
// sous-menu suppression d'une note
import NoteDeleteNav from '../NoteNav/NoteDeleteNav'

// Header invisible utilisé pour l'affichage du menu d'administration d'une note

const NoteNavBar = (props) => {

    // note à éditer (add / update / delete) et état des menus
    const { currentNote } = props

    if (currentNote) {
        return (
            <div className="note-navbar-container">
                <MainNav nameNav={'noteNav'} options={ {buttonVisibility: false} }>
                    
                    <MainNavItem nameNav={'addnote'}>
                        Ajout d'une note
                    </MainNavItem>

                    <MainNavItem nameNav={'updatenote'}>
                        Modification de la note
                    </MainNavItem>

                    <MainNavItem nameNav={'deletenote'}>
                        Suppression de la note
                    </MainNavItem>
                </MainNav>

                <SubNav nameNav={'addnote'}>
                    <NoteAddNav note={currentNote}></NoteAddNav>
                </SubNav>

                <SubNav nameNav={'updatenote'}>
                    <NoteUpdateNav note={currentNote}></NoteUpdateNav>
                </SubNav>

                <SubNav nameNav={'deletenote'}>
                    <NoteDeleteNav note={currentNote}></NoteDeleteNav>
                </SubNav>
            </div>
        )
    }
    else return ('')

}

const mapStateToProps = state => {
    return {
        currentNote: state.currentNote
    }
  }
  
  export default connect(mapStateToProps)(NoteNavBar)