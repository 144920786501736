import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DatePicker from 'react-date-picker'
import { readAndCompressImage } from 'browser-image-resizer'

// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { QUALITY_IMAGE, MAXWIDTH_IMAGE, MAXHEIGHT_IMAGE } from '../../commons.js'

// action redux liste des carnets
import { setTravelbooks } from '../../actions/index'
// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'



// Menu Ajout d'un carnet de voyage
const TravelbookAddNav = (props) => {

    const { travelbooks, setTravelbooks, setOpenMain, setOpenSub } = props

    const emptyForm = {
        id: '',
        slug: '',
        name: '',
        description: '',
        startdate: '',
        enddate: '',
        image: '',
        mkrcolors: ["#0000ff", "#00b2ee", "#ff0000"],
        visibility: true
    }
    
    // on mémorise l'item saisi via le formulaire
    const [formState, setFormState] = React.useState(emptyForm)

    const { slug, name, description, startdate, enddate, image, mkrcolors, visibility } = formState

    // on mémorise l'image saisie via le formulaire
    const [imageData, setImageData] = React.useState({ blob: null, base64: null })

    // message d'erreur
    const [ msgErr, setMsgErr ] = React.useState('') 

    // booleen initialisation du formulaire
    const [formReady, setFormReady] = React.useState(false)

    
    // gestion de la modification des champs du formulaire
    const onChangeField = (e) => {
        const valfield = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
        setFormState({ ...formState, [e.target.name]: valfield })
    }

    // gestion des dates du formulaire
    const onChangeDate = (date, name) => {
        if (!date) date = '';
        setFormState({ ...formState, [name]: date })
    }

    // changement de couleur
    const onChangeColor = (e, index) => {
        const [vcolor, icolor, ccolor] = mkrcolors;
        let newMkrcolors = []
        const newColor = e.target.value

        if (index === 0) newMkrcolors = [newColor, icolor, ccolor]
        else if (index === 1) newMkrcolors = [vcolor, newColor, ccolor]
        else if (index === 2) newMkrcolors = [vcolor, icolor, newColor]
        else newMkrcolors = [vcolor, icolor, ccolor]

        setFormState({ ...formState, mkrcolors: newMkrcolors })
    }

    // simulation du click
    const getFile = () => {
        fileInput.current.click()
    }

    // conversion Blob to Base64 pour une image
    const convertToBase64 = (blob) => {
        return new Promise(resolve => {
            var reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    }

    // modification de l'image
    const onChangeImage = async (e) => {

        e.preventDefault()

        const config = {
            quality: QUALITY_IMAGE,
            maxWidth: MAXWIDTH_IMAGE,
            maxHeight: MAXHEIGHT_IMAGE,
            autoRotate: true,
            debug: false
        }

        const file = e.target.files[0].name
        // lecture et redimensionnement de l'image
        let blobImage = await readAndCompressImage(
            e.target.files[0],
            config
        )
        // conversion base64
        let base64Image = await convertToBase64(blobImage)
        // memorisation des données de l'image
        setImageData({ blob: blobImage, base64: base64Image })
        // mise à jour du nom de fichier
        formState.image = file
        setFormState({ ...formState, image: file })
    }

    // effacement des données du formulaire
    const onClickErase = (e) => {
        e.preventDefault()
        setFormState({ ...emptyForm, image: '' })
        setImageData({ blob: null, base64: null })
    }


    // ajout d'un carnet
    const onClickAdd = (e) => {

        e.preventDefault()

        if ((!formState.id) && formState.name) {
            // conversion préalable des dates en chaine de caractères
            let startdateString = ''
            if (formState.startdate) startdateString = formState.startdate.toISOString()
            let enddateString = ''
            if (formState.enddate) enddateString = formState.enddate.toISOString()
            const tmpFormState = ({ ...formState, startdate: startdateString, enddate: enddateString })

            //  (post - add-travelbook) - envoi des données du formulaire + blob image vers le serveur
            let postData = null
            if ( !imageData.blob ) postData = ({ action: 'add-travelbook', ...tmpFormState  })
            else postData = ({ action: 'add-travelbook', imageData: imageData.blob, ...tmpFormState  })
            
            const data = new FormData()
            for (const property in postData) {
                data.append(property, postData[property])
            }

            axios.post(TRAVELSTEPS_SERVER_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(function (response) {
                    // en retour on récupère les proprietes id et slug
                    const addedTravelbook = { ...formState, id: response.data.id, slug: response.data.slug }
    
                    // mise à jour de la liste des carnets de voyage
                    if (travelbooks.length) {
                        // insertion en tete de liste
                        setTravelbooks([addedTravelbook].concat(travelbooks))
                    }
                    else setTravelbooks([addedTravelbook])

                    // effacement des données du formulaire
                    setFormState({ ...emptyForm, image: '' })
                    setImageData({ blob: null, base64: null })
                    // fermeture du menu principal et secondaire de la page d'accueil
                    setOpenMain(false)
                    setOpenSub(false)
                })
                .catch(function (error) {
                    console.log(error)
                    setMsgErr('>>> Ajout en erreur.')
                })
        }
        else {
            if (!formState.name) {
                setMsgErr('>>> Saisir un nom')
            }
            else if  (!formState.image) {
                setMsgErr('>>> Ajouter une image')               
            }
        }
    }

    const fileInput = React.createRef()

    // initialisation du formulaire
    React.useEffect(() => {

        const setFormData = async () => {
            try {
                // mise à zero du formulaire
                setFormState(emptyForm)
                // effacement image
                setImageData({ blob: null, base64: null })
                setMsgErr('')
                setFormReady(true)
            } catch (error) {
                console.log(error)
                setMsgErr('>>> Ajout en erreur.')
            }
        }

        setFormData()

       // eslint-disable-next-line
    }, [])
    
    if (!formReady) return ('')
    else return (
        <div className="add-travelbook-container container no-gutters">
            <p><strong>Ajout d'un carnet de voyage </strong></p>

            <form>
                <div className="form-group row">
                    <label htmlFor="addTbSlug" className="col-2 col-form-label col-form-label-sm">Slug: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="addTbSlug" name="slug" placeholder="Identifiant" value={slug} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addTbName" className="col-2 col-form-label col-form-label-sm">Nom: </label>
                    <div className="col-10">
                        <input type="text" className="form-control form-control-sm" id="addTbName" name="name" placeholder="Nom" value={name} onChange={onChangeField} />
                    </div>
                </div>
                <label htmlFor="addTbDesc" className="col-form-label col-form-label-sm">Description: </label>
                <div className="form-group row">
                    <div className="col-12">
                        <textarea id="addTbDesc" className="form-control form-control-sm" name="description" cols="40" rows="5" placeholder="Description" value={description} onChange={onChangeField} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addTbStartDate" className="col-2 col-form-label col-form-label-sm">Début: </label>
                    <div className="col-10">
                        <DatePicker value={startdate} onChange={(date) => onChangeDate(date,'startdate')} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="addTbEndDate" className="col-2 col-form-label col-form-label-sm">Fin: </label>
                    <div className="col-10">
                        <DatePicker value={enddate} onChange={(date) => onChangeDate(date, 'enddate')} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="addTbImageName" className="col-2 col-form-label col-form-label-sm">Image: </label>
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" id="addTbImageName" name="image" readOnly value={image} />
                    </div>
                    <input type="file" style={{ display: "none" }} id="fileName" name="fileName" ref={fileInput} onChange={onChangeImage} accept="image/*" />
                    <div className="col-3">
                        <button type="button" onClick={getFile} className="btn btn-primary btn-sm">Choisir</button>
                    </div>
                    <div className="image-container container-fluid d-flex justify-content-center">
                        <img className="img-responsive" src={imageData.base64} alt="" />
                    </div>
                </div>

                <fieldset>
                    <p>Couleur des marqueurs</p>
                    <div className="row">
                        <label htmlFor="addTbvColor" className="col-5 col-form-label col-form-label-sm">Visible: </label>
                        <div className="col-3">
                            <input type="color" className="form-control form-control-sm" id="addTbvColor" name="vColor" value={mkrcolors[0]} onChange={(e) => onChangeColor(e, 0)} />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="addTbiColor" className="col-5 col-form-label col-form-label-sm">Invisible: </label>
                        <div className="col-3">
                            <input type="color" className="form-control form-control-sm" id="addTbiColor" name="iColor" value={mkrcolors[1]} onChange={(e) => onChangeColor(e, 1)} />
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="addTbcColor" className="col-5 col-form-label col-form-label-sm">Courant: </label>
                        <div className="col-3">
                            <input type="color" className="form-control form-control-sm" id="addTbcColor" name="cColor" value={mkrcolors[2]} onChange={(e) => onChangeColor(e, 2)} />
                        </div>
                    </div>
                </fieldset>

                <div className="col-5 visibility-container form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="addTbVisibility" name="visibility" checked={visibility} onChange={onChangeField} />
                    <label className="form-check-label" htmlFor="addTbVisibility">Carnet visible</label>
                </div>

                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-content-between buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={onClickErase} className="btn btn-primary btn-sm">Effacer</button>
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={onClickAdd} className="btn btn-primary btn-sm">Ajouter</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

// Redux: mapping action creators
// Mise à jour de la liste de carnets et du menu de la page d'accueil
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setTravelbooks: setTravelbooks,
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        travelbooks: state.travelbooks
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelbookAddNav)