import React from 'react'

// Package css
import './AdminHome.scss'

// composants
import HomeNavBar from '../../components/NavBar/HomeNavBar'
import TravelbookList from './TravelbookList'

// page d'accueil de l'administration
// gestion de la liste des carnets de voyage
// ajout d'un carnet de voyage

const AdminHome = () => {

    return (
        <React.Fragment>
            <HomeNavBar></HomeNavBar>
            <TravelbookList></TravelbookList>
        </React.Fragment>
    )
}

export default AdminHome;