import React from 'react'
import { Link } from "react-router-dom"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Composants menus
import MainNav from "../Navigation/MainNav"
import MainNavItem from "../Navigation/MainNavItem"
import SubNav from "../Navigation/SubNav"

// sous-menu modification d'un carnet de voyage
import TravelbookUpdateNav from '../TravelbookNav/TravelbookUpdateNav'
// sous-menu suppression d'un carnet
import TravelbookDeleteNav from '../TravelbookNav/TravelbookDeleteNav'
// sous-menu gestion des traces
import TracksManageNav from '../TrackNav/TracksManageNav'
// sous-menu ajout d'une étape
import StepAddNav from '../StepNav/StepAddNav'

// action redux view slider (slider / scrollList)
import { setViewSlider } from '../../actions/index'

// action redux état de la carte
import { setMapReady } from '../../actions/index'

// affichage du nom du carnet courant

const TravelbookNavBar = (props) => {

    // carnet à éditer (update / delete) et état des menus
    const { currentTravelbook, steps, currentStep, viewSlider, setViewSlider, setMapReady } = props

    // ajout de l'item de menu - ajouter la première étape 
    const displayMainNavItem = (nameNav, label, steps) => {
        if (steps.length) return('')
        // affichage de l'item de menu principal
        else return( <MainNavItem nameNav={nameNav}>{label}</MainNavItem> )
    }

    // ajout du menu secondaire - ajouter la première étape     
    const displaySubNav = (nameNav, steps) => {
        if (steps.length) return('')
        // affichage du menu Ajout d'une premiere etape
        else {
            return(
                <SubNav nameNav={nameNav}>
                    <StepAddNav currentStep={currentStep}></StepAddNav>
                </SubNav>
            )
        }
    }

    // switch viewer 
    const switchViewer = (e) => {
        e.preventDefault()
        setViewSlider(!viewSlider) // bascule le mode d'affichage des notes entre slider et scrollList
    }

    // ajout du bouton viewer toggle
    const displayToggleButton = () => {
        if (viewSlider) return ('fas fa-toggle-on')
        else return ('fas fa-toggle-off')
    }

    const leaveTravelbook = () => {
        // redirection vers la page d'accueil
        setMapReady(false)        
    }

    return (
        <div className="travelbook-navbar-container">
            <div className="navbar-container">
                <div className="title"><Link to="/" onClick={leaveTravelbook}><span>{currentTravelbook.name}</span></Link></div>
                <button type="button" className="toggle">
                    <span className="toggle-icon" onClick={switchViewer}>
                        <i className={displayToggleButton()}></i>
                    </span>
                </button>
            </div>
            <MainNav nameNav={'travelbookNav'}>

                <MainNavItem nameNav={'updatetravelbook'}>
                    Modification du carnet de voyage
                </MainNavItem>

                <MainNavItem nameNav={'deletetravelbook'}>
                    Suppression du carnet de voyage
                </MainNavItem>

                <MainNavItem nameNav={'managetracks'}>
                    Gestion des traces
                </MainNavItem>

                { displayMainNavItem('addfirststep', 'Ajout de la première étape', steps) }

            </MainNav>

            <SubNav nameNav={'updatetravelbook'}>
                <TravelbookUpdateNav currentTravelbook={currentTravelbook}></TravelbookUpdateNav>
            </SubNav>

            <SubNav nameNav={'deletetravelbook'}>
                <TravelbookDeleteNav currentTravelbook={currentTravelbook}></TravelbookDeleteNav>
            </SubNav>

            <SubNav nameNav={'managetracks'}>
                <TracksManageNav currentTravelbook={currentTravelbook}></TracksManageNav>
            </SubNav>

            { displaySubNav('addfirststep', steps) }

        </div>
    )
}

// Redux: mapping action creators - setTracks
// Mise à jour du menu carnet de voyage courant
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setViewSlider: setViewSlider,  // selection de la vue Slider / ScrollLlist
        setMapReady: setMapReady
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        steps: state.steps,
        viewSlider: state.viewSlider
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelbookNavBar)