import React from 'react'
import { connect } from 'react-redux'

// composant Carnet de voyage courant
import TravelbookNavBar from '../../components/NavBar/TravelbookNavBar'
// composant Etape courante
import StepNavBar from '../../components/NavBar/StepNavBar'
// composant Note courante
import NoteNavBar from '../../components/NavBar/NoteNavBar'
// composant liste des notes
import NotesViewer from './NotesViewer'

const TravelPanel = (props) => {

  // Container Panneau
  const { currentTravelbook, currentStep, steps } = props

  return (
    <div className="panel-container">
      <div id="panel">
        <TravelbookNavBar currentTravelbook={currentTravelbook}></TravelbookNavBar>
        <StepNavBar currentStep={currentStep}></StepNavBar>
        <NoteNavBar></NoteNavBar>
        <div id="viewer-zone"><NotesViewer steps={steps}></NotesViewer></div>
      </div>
    </div>
  )
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
    currentTravelbook: state.currentTravelbook,
    currentStep: state.currentStep,
    steps: state.steps
  }
}

export default connect(mapStateToProps)(TravelPanel)