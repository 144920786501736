import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'


// constantes applicatives
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'
import { IMAGE_BASE_URL } from '../../commons'

// action redux liste des carnets
import { setTravelbooks } from '../../actions/index'
// action redux menu principal
import { setOpenMain } from '../../actions/index'
// action redux menu secondaire
import { setOpenSub } from '../../actions/index'

const TravelbookDeleteNav = (props) => {

    const { currentTravelbook, travelbooks, setTravelbooks, setOpenMain, setOpenSub } = props

    // message d'erreur
    const [msgErr, setMsgErr] = React.useState('')

    const history = useHistory()

    // suppression du carnet
    const onClickDelete = (e, history) => {

        e.preventDefault()

        if ((currentTravelbook.steps && currentTravelbook.steps.length) || (currentTravelbook.tracks && currentTravelbook.tracks.length)) {
            setMsgErr('>>> Suppression impossible. Le carnet comporte encore des étapes.')
        }
        else {
            // envoi des données vers le serveur pour suppression du carnet
            const data = new FormData()
            data.append('action', 'delete-travelbook')
            data.append('id', currentTravelbook.id)

            axios.post(TRAVELSTEPS_SERVER_URL, data)
                .then(function (response) {
                    // mise à jour de la liste des carnets de voyage
                    const newTravelbooks = [...travelbooks]
                    const index = newTravelbooks.findIndex(travelbook => travelbook.slug === currentTravelbook.slug)
                    // suppression de la liste
                    newTravelbooks.splice(index, 1)
                    // mise à jour de la liste
                    setTravelbooks(newTravelbooks)
                    
                    // fermeture des menus principal et secondaire
                    setOpenMain(false)
                    setOpenSub(false)
                    // redirection vers la liste des carnets en page d'accueil
                    history.push('/')
                })
                .catch(function (error) {
                    console.log(error)
                    setMsgErr('>>> Suppression en erreur.')
                })
        }
    }

    // effacement du message
    React.useEffect(() => {
        setMsgErr('')
        // eslint-disable-next-line
    }, [])

    return (
            <div className="delete-travelbook-container container no-gutters">
            <p><strong>Suppression du carnet de voyage </strong></p>
            <form>
                <div className="travelbook-item-container">
                    <h2>{currentTravelbook.name}</h2>
                    <p>{currentTravelbook.description}</p>
                    <div className="container-fluid d-flex justify-content-center">
                        <img className="img-fluid" src={`${IMAGE_BASE_URL}/${currentTravelbook.slug}/image/${currentTravelbook.image}`} alt="" />
                    </div>
                </div>
                <div className="msgerr"><span>{msgErr}</span></div>
                <div className="row no-gutters justify-center buttons-container">
                    <div className="col-4">
                        <button type="button" onClick={(e) => onClickDelete(e, history)} className="btn btn-primary btn-sm">Supprimer</button>
                    </div>
                </div>
            </form>
        </div>
    )

}

// Redux: mapping action creators
// Mise à jour de la liste de carnets et du menu de la page d'accueil
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setTravelbooks: setTravelbooks,
        setOpenMain: setOpenMain,
        setOpenSub: setOpenSub
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        travelbooks: state.travelbooks
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelbookDeleteNav)