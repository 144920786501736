import React from 'react'
import { connect } from 'react-redux'

// Composants menus
import MainNav from "../Navigation/MainNav"
import MainNavItem from "../Navigation/MainNavItem"
import SubNav from "../Navigation/SubNav"

// sous-menu ajout d'une étape
import StepAddNav from '../StepNav/StepAddNav'
// sous-menu modification d'une étape
import StepUpdateNav from '../StepNav/StepUpdateNav'
// sous-menu suppression d'une étape
import StepDeleteNav from '../StepNav/StepDeleteNav'
// sous-menu ajout d'une note
import NoteAddNav from '../NoteNav/NoteAddNav'

const StepNavBar = (props) => {

  // étape à éditer (add / update / delete) et état des menus
  const { currentStep, currentNote } = props

  // ajout de l'item de menu - ajouter la première note 
  const displayMainNavItem = (nameNav, label, notes) => {
    if (notes.length) return ('')
    // affichage de l'item de menu principal
    else return (<MainNavItem nameNav={nameNav}>{label}</MainNavItem>)
  }

  // ajout du menu secondaire - ajouter la première note     
  const displaySubNav = (nameNav, notes, currentNote) => {
    if (notes.length) return ('')
    // affichage du menu Ajout d'une premiere note
    else {
      return (
        <SubNav nameNav={nameNav}>
          <NoteAddNav note={currentNote}></NoteAddNav>
        </SubNav>
      )
    }
  }

  if (currentStep) {
    return (
      <div className="step-navbar-container">
        <div className="navbar-container">
          <div className="title">{currentStep.title}</div>
        </div>

        <MainNav nameNav={'stepNav'} options={ {iconStyle: 'menu-dark-icon'} }>
          <MainNavItem nameNav={'addstep'}>
            Ajout d'une étape
          </MainNavItem>

          <MainNavItem nameNav={'updatestep'}>
            Modification de l'étape
          </MainNavItem>

          <MainNavItem nameNav={'deletestep'}>
            Suppression de l'étape
                </MainNavItem>

          {displayMainNavItem('addfirstnote', 'Ajout de la première note', currentStep.notes)}

        </MainNav>

        <SubNav nameNav={'addstep'}>
          <StepAddNav currentStep={currentStep}></StepAddNav>
        </SubNav>

        <SubNav nameNav={'updatestep'}>
          <StepUpdateNav currentStep={currentStep}></StepUpdateNav>
        </SubNav>

        <SubNav nameNav={'deletestep'}>
          <StepDeleteNav currentStep={currentStep}></StepDeleteNav>
        </SubNav>

        {displaySubNav('addfirstnote', currentStep.notes, currentNote)}

      </div>
    )
  }
  else return ('')

}

const mapStateToProps = state => {
  return {
      currentStep: state.currentStep,
      currentNote: state.currentNote
  }
}

export default connect(mapStateToProps)(StepNavBar)