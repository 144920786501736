import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setOpenMain } from '../../actions/index'
import { setNameMain } from '../../actions/index'
import { setCurrentStep } from '../../actions/index'
import { setCurrentNote } from '../../actions/index'

import Comment from "./CommentNavBar"

import { IMAGE_BASE_URL } from '../../commons'

const CommentNavBar = (props) => {

  const { note, stepId, currentStep, setCurrentStep, steps, setCurrentNote, setNameMain, setOpenMain, openMain } = props

  const HandleClickNote = (e, note, stepId) => {
    // changement de note courante
    setCurrentNote(note)

    // changement d'étape courante
    // clicker sur une note peut entrainer un changement d'étape courante
    if (stepId !== currentStep.id) {
        const index = steps.findIndex(step => step.id === stepId) // index de l'étape à laquelle la note appartient
        const newStep = steps[index]
        setCurrentStep(newStep)
    }

    // ouverture du menu principal note et fermeture des autres menus et sous-menus
    setNameMain('noteNav')
    setOpenMain(!openMain)
}


  return (
    <div className="comment-navbar-container">
      <div className="footer">
        <button type="button" className="comment-button"><span className="comment-icon"><i className="far fa-comment"></i></span></button>
        <button type="button" className="menu-button"><span className="menu-icon" onClick={(e) => HandleClickNote(e, note, stepId)}><i className="fas fa-bars"></i></span></button>
      </div>
    </div>
  )
}

// Redux: mapping action creators
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      setCurrentStep: setCurrentStep,
      setCurrentNote: setCurrentNote,
      setNameMain: setNameMain,
      setOpenMain: setOpenMain
  }, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
      currentStep: state.currentStep,
      steps: state.steps,
      openMain: state.openMain
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentNavBar)